export function getPromoPricingUIAssetContainerName(
  promoPricingUIConfig,
  isVip
) {
  const offerLabelContainerName = isVip
    ? promoPricingUIConfig?.vipAssetContainer
    : promoPricingUIConfig?.nonVipAssetContainer;

  return offerLabelContainerName || 'activating_offer_label';
}
