import React from 'react';

import styled from 'styled-components';

import {
  desktop,
  SkeletonLine as BaseSkeletonLine, // TODO: It will be updated with the new loading animation.
  SkeletonPulse as SkeletonAnimation, // TODO: It will be updated with the new loading animation.
} from '~/techstyle-shared/react-components';

import { ProductInfoContainer } from '../VideoCarouselProductInfo';

const ProductCardSkeleton = styled(SkeletonAnimation)`
  --product-card-width: ${({ theme: { videoCarousel: carousel } }) =>
    carousel.productThumbnail.desktop.width +
    2 * carousel.productThumbnail.border}px;
  --product-card-height: ${({ theme: { videoCarousel: carousel } }) =>
    carousel.productThumbnail.desktop.height +
    2 * carousel.productThumbnail.border}px;

  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.palette.white};
  width: var(--product-card-width);
  height: var(--product-card-height);
  flex-shrink: 0;
  align-self: flex-end;

  ${desktop`
    --product-card-width: ${({ theme: { videoCarousel: carousel } }) =>
      carousel.productThumbnail.mobile.width +
      2 * carousel.productThumbnail.border}px;
    --product-card-height: ${({ theme: { videoCarousel: carousel } }) =>
      carousel.productThumbnail.mobile.height +
      2 * carousel.productThumbnail.border}px;
  `}
`;

const StyledSkeletonLine = styled(BaseSkeletonLine)`
  height: ${({ theme: { sizes } }) => sizes.md - 2}px;
  width: ${({ short }) => (short ? '71%' : '100%')};

  ${desktop`
    height: ${({ theme: { sizes } }) => sizes.md + 4}px;
    width: ${({ short }) => (short ? '66.7%' : '97%')};
  `}
`;

const ProductNameLabel = styled.p`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { sizes } }) => sizes.xxxs + 1}px;
  width: 100%;

  ${desktop`
    gap: ${({ theme: { sizes } }) => sizes.xxs}px;
  `}
`;

export default function VideoCarouselProductInfoSkeleton() {
  return (
    <ProductInfoContainer>
      <ProductCardSkeleton />
      <ProductNameLabel>
        <StyledSkeletonLine />
        <StyledSkeletonLine short />
      </ProductNameLabel>
    </ProductInfoContainer>
  );
}
