import { useMembership } from '~/techstyle-shared/react-accounts';

import useEMPMember from './useEMPMember';

export default function useEMPPricing(product) {
  const { isEMP: isEMPUser } = useEMPMember();
  const { isVip } = useMembership();
  const tokenValue = product
    ? product.tokenRedemptionValue || product.tokenRedemptionQuantity
    : 0;
  const hasTokenValue = Boolean(tokenValue && tokenValue > 0);
  const isTokenOnly =
    product &&
    (product.is_token || product.isToken || product.isTokenOnly) &&
    hasTokenValue &&
    isVip &&
    isEMPUser;
  const showMemberCredits = hasTokenValue && isVip && isEMPUser;

  return { showMemberCredits, isTokenOnly, hasTokenValue, tokenValue };
}
