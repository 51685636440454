import { useCallback, useEffect } from 'react';

import { useRouter } from 'next/router';

import { SignupMemberships } from '~/shared/constants/memberships';
import {
  useMembership,
  useCustomerDetail,
  useAccountActions,
} from '~/techstyle-shared/react-accounts';
import {
  useSessionDetail,
  useSessionActions,
  useSession,
} from '~/techstyle-shared/redux-core';

const useGshop = () => {
  const { isLoggedIn } = useSession();
  const accountActions = useAccountActions();
  const sessionActions = useSessionActions();
  const { query: { promo = '' } = {} } = useRouter();

  const gShopCustomerDetail = useCustomerDetail('GSHOP');
  let isGshopInCustomerDetails = false;
  if (isLoggedIn) {
    if (gShopCustomerDetail?.value) {
      isGshopInCustomerDetails = true;
    }
  }
  const isGshopInSessionDetails = useSessionDetail('GSHOP')?.value === '1';

  useEffect(() => {
    if (isGshopInSessionDetails && !isGshopInCustomerDetails && isLoggedIn) {
      accountActions.updateCustomerDetail({
        name: 'GSHOP',
        value: true,
        datetimeAdded: new Date(),
      });
    }
  }, [
    isLoggedIn,
    accountActions,
    isGshopInSessionDetails,
    isGshopInCustomerDetails,
  ]);

  const isGshopActive = isGshopInSessionDetails || isGshopInCustomerDetails;
  const { isVip } = useMembership();
  const isGshopInQuery = promo.toLowerCase().includes('gshop');

  const activateGshop = useCallback(() => {
    if (isGshopInQuery && !isVip) {
      sessionActions.updateSessionDetail({
        name: SignupMemberships.GSHOP,
        value: '1',
      });
    }

    // deactivate GSHOP in sessionDetail and customerDetail once customer signs up for VIP
    if (isGshopActive && isVip) {
      sessionActions.updateSessionDetail({
        name: SignupMemberships.GSHOP,
        value: '0',
      });

      accountActions.updateCustomerDetail({
        name: 'GSHOP',
        value: false,
        datetimeAdded: new Date(),
      });
    }
  }, [isGshopInQuery, isVip, sessionActions, isGshopActive, accountActions]);

  return {
    isGshopActive,
    activateGshop,
  };
};

export default useGshop;
