import React, { memo } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { mobile, useTheme } from '~/techstyle-shared/react-components';

const ProfileImage = styled.div`
  --avatar-size: ${({ theme: { videoCarousel: carousel } }) =>
    carousel.avatar.desktop}px;
  width: var(--avatar-size);
  height: var(--avatar-size);
  border-radius: calc(var(--avatar-size) / 2);
  border: 1px solid ${({ theme }) => theme.colors.palette.white};
  background: url(${(props) => props.src})
    ${({ theme }) => theme.colors.shoppableVideoAuthorProfileImageBg} 50% /
    cover no-repeat;
`;

const Title = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3;
  font-family: ${({ theme: { fontStacks } }) =>
    fontStacks.standardGtPressuraFamily};
  color: ${({ theme }) => theme.colors.palette.white};
`;

export const VideoAuthorWrapper = styled.div`
  position: absolute;
  top: ${({ theme: { sizes } }) => sizes.md + 2}px;
  left: ${({ theme: { sizes }, $inModal }) => sizes.md + ($inModal ? 2 : -2)}px;
  display: flex;
  align-items: center;
  gap: ${({ theme: { sizes } }) => sizes.md / 2 - 1}px;
  min-height: ${({ theme: { videoCarousel: carousel } }) =>
    carousel.avatar.desktop}px;

  ${mobile`
    top: ${({ $mobileTopValue }) => $mobileTopValue}px;
    left: ${({ $mobileLeftValue }) => $mobileLeftValue}px;
    ${({ theme: { videoCarousel: carousel }, $inModal }) =>
      !$inModal &&
      `
      min-height: ${carousel.avatar.mobile}px;

      ${ProfileImage} {
        --avatar-size: ${carousel.avatar.mobile}px;
      }

      ${Title} {
        font-size: 0.875rem;
      }
    `};
  `}
`;

function VideoAuthor({ author, inModal = false }) {
  const theme = useTheme();

  if (!author) {
    return null;
  }

  const mobileTopValue = inModal ? theme.sizes.sm + 3 : theme.sizes.sm;
  const mobileLeftValue = inModal ? theme.sizes.sm + 4 : theme.sizes.sm;

  return (
    <VideoAuthorWrapper
      inModal={inModal}
      $mobileTopValue={mobileTopValue}
      $mobileLeftValue={mobileLeftValue}
    >
      {author.displayImage && <ProfileImage src={author.displayImage} />}

      <Title>{author.name}</Title>
    </VideoAuthorWrapper>
  );
}

VideoAuthor.propTypes = {
  author: PropTypes.object,
  inModal: PropTypes.bool,
};

export default memo(VideoAuthor);
