import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { useMediaQuery } from '~/shared/utils/useMediaQuery';
import {
  landscapeMobileQuery,
  mobilexsLandscape,
} from '~/shoppableVideo/utils/';
import {
  desktop,
  mobile,
  Picture,
  useBreakpoint,
  useTheme,
} from '~/techstyle-shared/react-components';

import { getGroupedImages } from '../utils/getGroupedImages';

const PictureWrapper = styled.div`
  position: relative;
  max-height: fill-available;
`;

const PictureDisplayOnHover = styled(Picture)`
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  max-height: fill-available;
  ${({ $index }) =>
    `${PictureWrapper}[data-wrapper-id="${$index}"]:hover & {
      display: block;
    }`}

  & img {
    border: none;
  }
`;

const StyledPicture = styled(Picture)`
  && {
    height: auto;
    max-height: fill-available;
    & img {
      border: none;
    }
  }
`;

const MobileImageSetWrapperStyle = css`
  height: 100%;
  width: auto;

  > *:not(:first-child) {
    display: none;
  }
`;

const ImageSetCollectionWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  gap: ${({ theme: { videoCarousel: carousel } }) => carousel.padding / 2}px;
  width: 100%;
  justify-content: space-between;

  ${desktop`
    > * {
      max-width: 50%;
    }
  `}

  ${mobile(MobileImageSetWrapperStyle)}

  ${mobilexsLandscape`
    ${MobileImageSetWrapperStyle}
    bottom: 37px;
  `}
`;

/**
 * @component ImageSet
 * @description ImageSet component renders a set of images for a product.
 * It displays a single image on mobile and landscape mobile views,
 * and displays an image with a hover effect on desktop views.
 *
 * @param {Object} props - The component props.
 * @param {number} props.index - The index of the image set.
 * @param {string} props.altImageSetLabel - The alt text for the image set.
 * @param {Array} props.images - An array of image sources for the image set.
 *
 * @returns {JSX.Element} The rendered ImageSet component.
 */
function ImageSet({ index, altImageSetLabel, images }) {
  const theme = useTheme();
  const cssHelper = theme.videoCarousel;
  const isLandscapeMobile = useMediaQuery(landscapeMobileQuery(theme));
  const { isMobile } = useBreakpoint();

  const pictureProps = {
    sources: images[0],
    alt: altImageSetLabel,
    breakpoints: [
      {
        minWidth: 0,
        maxWidth: cssHelper.productThumbnail.mobile.width,
        pictureWidth: cssHelper.productThumbnail.mobile.width,
      },
    ],
    loading: 'lazy',
  };

  if (isMobile || isLandscapeMobile) {
    return <Picture key={index} {...pictureProps} />;
  }

  return (
    <PictureWrapper data-wrapper-id={index}>
      <StyledPicture {...pictureProps} />
      {images[1] && (
        <PictureDisplayOnHover
          $index={index}
          {...pictureProps}
          sources={images[1]}
        />
      )}
    </PictureWrapper>
  );
}

ImageSet.propTypes = {
  altImageSetLabel: PropTypes.string.isRequired,
  images: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
};

/**
 * @component ImageSetCollection
 * @description ImageSetCollection component renders a collection of image sets for a product.
 * It groups images based on the device type (mobile or desktop) and displays them accordingly.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.imageSets - An array of image sets for the product.
 * @param {string} props.productLabel - The label of the product.
 *
 * @returns {JSX.Element} The rendered ImageSetCollection component.
 */
function ImageSetCollection({ imageSets, productLabel }) {
  const altImageSetLabel = productLabel || 'product image';
  const { isMobile } = useBreakpoint();
  const groupedImages = useMemo(
    () => getGroupedImages({ imageSets, isMobile }),
    [imageSets, isMobile]
  );

  return (
    <ImageSetCollectionWrapper>
      {groupedImages.map((images = [], index) => {
        /**
         * @description this images check is required in the event `groupedImages` only contains
         * one set of images (2). Because the accumulator of `getGroupedImages` is initialized with
         * two empty arrays, the second array will be empty if there is only one set of images.
         *
         * This is placed here, rather than in `ImageSet` because it is an implementation detail and limitation of
         * this `ProductCardWithLabel` and should not be considered an issue with `ImageSet`. We want to not
         * render `ImageSet` at all if there are no images to render.
         */
        if (images.length === 0) {
          return null;
        }

        return (
          <ImageSet
            key={productLabel + '-' + index + (index === 0 ? 0 : 1)}
            index={index}
            altImageSetLabel={altImageSetLabel}
            images={images}
          />
        );
      })}
    </ImageSetCollectionWrapper>
  );
}

ImageSetCollection.propTypes = {
  imageSets: PropTypes.array.isRequired,
  productLabel: PropTypes.string.isRequired,
};

export default ImageSetCollection;
