import React from 'react';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import useVideoRelatedProduct from '~/shoppableVideo/utils/VideoCarousel/useVideoRelatedProduct';
import { desktop, useBreakpoint } from '~/techstyle-shared/react-components';
import { ProductListViewedProvider } from '~/techstyle-shared/react-products';

import LoadingProductsSkeleton from '../LoadingProductsSkeleton';
import ProductCardWithLabel from '../ProductCardWithLabel';

const Wrapper = styled.div`
  height: 100%;

  ${({ $desktopView }) =>
    $desktopView &&
    css`
      ${desktop`
        padding: 29px;
        position: absolute;
        margin-left: 50%;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        > * {
          width: 100%;
        }
      `}
    `}
  }
`;

const VideoModalProductCell = ({ video, isLandscapeMobile }) => {
  const { isMobile } = useBreakpoint();
  const { product, isProductLoading, isLoadingProductPricing } =
    useVideoRelatedProduct(video);

  return (
    <Wrapper $desktopView={!isLandscapeMobile}>
      {isProductLoading && isMobile && <LoadingProductsSkeleton />}
      {product && (
        <ProductListViewedProvider>
          <ProductCardWithLabel
            key={product.masterProductId}
            product={product}
            isLoadingProductPricing={isLoadingProductPricing}
          />
        </ProductListViewedProvider>
      )}
    </Wrapper>
  );
};

VideoModalProductCell.propTypes = {
  isLandscapeMobile: PropTypes.bool.isRequired,
  video: PropTypes.object,
};

export default VideoModalProductCell;
