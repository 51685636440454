import React, { useEffect, useRef, useState } from 'react';

import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import styled, { css, useTheme } from 'styled-components';

import CloseButton from '~/shared/components/CloseButton';
import { useMediaQuery } from '~/shared/utils/useMediaQuery';
import { useWindowSize } from '~/shared/utils/useWindowSize';
import {
  landscapeMobileQuery,
  mobilexsLandscape,
} from '~/shoppableVideo/utils/';
import { captureVideoError } from '~/shoppableVideo/utils/captureVideoError';
import useMaxModalWindowSize from '~/shoppableVideo/utils/useMaxModalWindowSize';
import { useVideoCarouselContext } from '~/shoppableVideo/utils/VideoCarouselContext';
import { desktop, mobile } from '~/techstyle-shared/react-components';
import { FormattedMessage, useIntl } from '~/techstyle-shared/react-intl';

import ModalDialog from '../ModalDialog';
import RotateDeviceBanner from '../RotateDeviceBanner';
import ShadowShim from '../ShadowShim';
import { VideoAuthorWrapper } from '../VideoAuthor';
import VideoWithProduct from '../VideoWithProduct';

const MobileModalCloseButtonStyle = css`
  color: white;
  font-size: 1.25rem;
  padding: ${({ theme: { sizes } }) => `${sizes.md + 3}px ${sizes.md - 2}px`};
  * {
    stroke-width: 1;
  }
`;

const ModalCloseButton = styled(CloseButton)`
  font-size: 1.375rem;
  padding: ${({ theme: { sizes } }) => `${sizes.lg - 4}px ${sizes.lg}px`};
  position: absolute;
  right: 0;
  top: 0;
  z-index: ${({ theme }) => theme.zIndexes.tooltip};
  * {
    stroke-width: 1.75;
  }

  ${mobile`${MobileModalCloseButtonStyle}`}
  ${mobilexsLandscape`
    ${MobileModalCloseButtonStyle}
    top: -4px;
    right: 21px;
  `}
`;

const StyledModal = styled(ModalDialog)`
  overflow: hidden;
  border-radius: ${({ theme: { sizes } }) => sizes.md - 2}px;
  height: ${({ $height }) =>
    $height ? $height + 'px' : 'calc(100vh - 128px)'};
  width: ${({ $width }) => ($width ? $width + 'px' : '100vw')};
  padding: 0;
  &:focus-visible {
    outline: none;
  }
  border: none;
  overscroll-behavior: trap;

  ${desktop`
    min-height: 560px;
  `}

  ${mobile`
     margin: 0;
     border-radius: 0;
     min-height: -moz-available;
     min-height: -webkit-fill-available;
     min-height: fill-available;
     min-height: ${({ $windowHeight }) => $windowHeight}px;
     height: ${({ $windowHeight }) => $windowHeight}px;
     width: ${({ $windowWidth }) => $windowWidth}px;
     min-width: -webkit-fill-available;
     max-height: ${({ $windowHeight }) => $windowHeight}px;
     max-height: -webkit-fill-available;
     min-width: 100vw;
     bottom: 0;
     left: 0;
     ::-webkit-scrollbar {
      display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;
  `}

  ${mobilexsLandscape`
    border-radius: 0;
    margin: 0;
    top: 0;
    left: 0;
    right: 0;
    min-width: auto;
    background-color: black;
    min-height: auto;
    width: 100vw;
    max-width: 100vw;
    width: -webkit-fill-available;

    /* Avoid Chrome to see Safari hack */
    @supports (-webkit-touch-callout: none) {
        /* The hack for Safari */
        height: -webkit-fill-available;
      }
    }
  `};
`;

const StyledRotateDeviceBanner = styled(RotateDeviceBanner)`
  min-width: 100vw;
  padding-inline: ${({ theme: { sizes } }) => sizes.xxl}px;
  z-index: ${({ theme }) => theme.zIndexes.toast} !important;
`;

const StyledVideoWithProduct = styled(VideoWithProduct)`
  ${ShadowShim} {
    ${mobile`
      max-width: none;
    `}
  }
  ${VideoAuthorWrapper} {
    z-index: 1;
  }
`;

function VideoModal() {
  const intl = useIntl();
  const { videoInModal: modalVideo, closeModal } = useVideoCarouselContext();
  const [isModalOpen, setIsModalOpen] = useState(!!modalVideo);
  const playerRef = useRef();
  const theme = useTheme();
  const isLandscapeMobile = useMediaQuery(landscapeMobileQuery(theme));
  const { height: windowHeight, width: windowWidth } = useWindowSize();
  const modalSize = useMaxModalWindowSize(windowHeight, windowWidth);

  const closeLabel = intl.formatMessage({
    defaultMessage: 'Close',
    id: 'global_cta.close',
  });

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isModalOpen) {
      disablePageScroll(document.body);
    } else {
      enablePageScroll(document.body);
    }
    return () => {
      enablePageScroll(document.body);
    };
  }, [isModalOpen]);

  useEffect(() => {
    setIsModalOpen(!!modalVideo);
  }, [modalVideo]);

  return (
    <>
      <StyledModal
        $height={modalSize.height}
        $width={modalSize.width}
        $windowHeight={windowHeight}
        $windowWidth={windowWidth}
        isOpen={isModalOpen}
        onClose={closeModal}
        onCancel={closeModal}
        onScroll={(e) => e.stopPropagation()}
        onTouchMove={(e) => e.stopPropagation()}
        aria-label={intl.formatMessage({
          defaultMessage: 'Product Video',
          id: 'site_shoppable_video.modal',
        })}
      >
        <ModalCloseButton aria-label={closeLabel} onClick={handleModalClose} />
        <StyledRotateDeviceBanner
          text={
            <FormattedMessage
              id="shoppable_video.rotate_device_carousel"
              defaultMessage="Rotate phone for a better experience"
            />
          }
        />
        {modalVideo && (
          <StyledVideoWithProduct
            forceHLS
            video={modalVideo}
            wrapperStyle={{ height: '100%' }}
            showControls={false}
            isLandscapeMobile={isLandscapeMobile}
            isPlaying={isModalOpen}
            ref={playerRef}
            onError={(error) => {
              captureVideoError({
                error,
                label: 'Video Carousel',
                data: { video: modalVideo, isFullscreen: true },
              });
            }}
          />
        )}
      </StyledModal>
    </>
  );
}

export default VideoModal;
