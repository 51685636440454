import React from 'react';

import PropTypes from 'prop-types';

export default function EllipseNavigationIcon({
  color = 'currentColor',
  height = '44',
  width = '44',
}) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 44 44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="22" cy="22" r="21.5" fill="white" stroke="#EFF0F0" />
      <path
        d="M14.2362 21.3635C13.9213 21.7379 13.9213 22.2621 14.2362 22.6365L23.4331 33.7192C23.748 34.0936 24.252 34.0936 24.5039 33.7192L25.7638 32.2215C26.0787 31.922 26.0787 31.3229 25.7638 30.9485L18.3307 22.0374L25.7638 13.0515C26.0787 12.6771 26.0787 12.1529 25.7638 11.7785L24.5039 10.2808C24.252 9.9064 23.748 9.9064 23.4331 10.2808L14.2362 21.3635Z"
        stroke={color}
        fill={color}
      />
    </svg>
  );
}

EllipseNavigationIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
