import React, { createContext, useCallback, useContext, useState } from 'react';

import noop from 'lodash/noop';
import PropTypes from 'prop-types';

import { useTheme } from '~/techstyle-shared/react-components';

export const VideoCarouselContext = createContext({
  isOnPDP: false,
  isThumbnailsMode: false,
  hasGrayBackground: false,
  subTitle: null,
  hideProductNameInTitle: false,
  legalDisclaimer: null,
  videoInModal: null,
  carouselTheme: {},
  closeModal: noop,
  openModal: noop,
  setVideoProducts: noop,
  getVideoRelatedProduct: noop,
  isLoadingProducts: false,
  setIsLoadingProducts: noop,
  trackingLabel: 'carousel',
});

export const useVideoCarouselContext = () => {
  const context = useContext(VideoCarouselContext);

  if (!context) {
    throw new Error(
      'useVideoCarouselContext must be used within a VideoCarouselProvider'
    );
  }

  return context;
};

export const VideoCarouselProvider = ({
  children,
  isOnPDP = false,
  isThumbnailsMode = false,
  hideProductNameInTitle = false,
  subTitle,
  hasGrayBackground = false,
  legalDisclaimer,
}) => {
  const [videoInModal, setVideoInModal] = useState(null);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const closeModal = useCallback(() => setVideoInModal(null), []);
  const openModal = useCallback((video) => setVideoInModal(video), []);
  // Map of videoId to related product
  const [videoRelatedProducts, setVideoRelatedProducts] = useState(
    () => new Map()
  );

  /**
   * Set related products for each video in the carousel. This is used to display the product info.
   * This function maps the videoId to the related product data for easy access in other components.
   * @param {Array} products - Array of products coming from the API
   * @param {Object} videoIdToProductIds - Map of videoId to each correspondent productId (each video should have one product associated)
   */
  const setVideoProducts = useCallback((products, videoIdToProductIds) => {
    const productIdsMap = products.reduce((acc, product) => {
      if (!product.masterProductId) {
        return acc;
      }

      acc[product.masterProductId] = product;
      return acc;
    }, {});

    const videoIdToProductData = new Map(
      Object.entries(videoIdToProductIds).map(([videoId, productId]) => [
        videoId,
        productIdsMap[productId],
      ])
    );
    setVideoRelatedProducts(videoIdToProductData);
  }, []);

  const getVideoRelatedProduct = useCallback(
    (videoId) => videoRelatedProducts.get(videoId),
    [videoRelatedProducts]
  );
  const theme = useTheme();
  let carouselTheme = theme.videoCarousel;
  if (isOnPDP && isThumbnailsMode) {
    carouselTheme = {
      ...carouselTheme,
      ...carouselTheme.thumbnailsCarousel,
    };
  }

  let trackingLabel = 'homepage_video_carousel';
  if (isOnPDP) {
    trackingLabel = isThumbnailsMode
      ? 'pdp_thumbnail_video_carousel'
      : 'pdp_video_carousel';
  }

  return (
    <VideoCarouselContext.Provider
      value={{
        videoInModal,
        closeModal,
        openModal,
        isOnPDP,
        isThumbnailsMode,
        hideProductNameInTitle,
        subTitle,
        hasGrayBackground,
        setVideoProducts,
        getVideoRelatedProduct,
        isLoadingProducts,
        setIsLoadingProducts,
        legalDisclaimer,
        carouselTheme,
        trackingLabel,
      }}
    >
      {children}
    </VideoCarouselContext.Provider>
  );
};

VideoCarouselProvider.propTypes = {
  children: PropTypes.node,
  hasGrayBackground: PropTypes.bool,
  hideProductNameInTitle: PropTypes.bool,
  isOnPDP: PropTypes.bool,
  isThumbnailsMode: PropTypes.bool,
  legalDisclaimer: PropTypes.string,
  subTitle: PropTypes.string,
};
