import { useRouter } from 'next/router';
import { isMobile, isSafari } from 'react-device-detect';

/**
 * @description This hook is used to determine if HLS is needed for the video player based on the device and query params.
 ** The query param `desktopTest` can be used to force HLS on desktop for testing purposes.
 * @returns {boolean} forceHLS
 */
export default function useForceHLS() {
  const router = useRouter();

  const hlsNeeded = !(isMobile || isSafari);
  const forceHLS =
    hlsNeeded ||
    router.query.desktopTest === 'true' ||
    router.query.desktopTest === '1';

  return forceHLS;
}
