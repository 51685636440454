import PropTypes from 'prop-types';

const useMaxModalWindowSize = (
  browserHeight,
  browserWidth,
  aspectRatio = 1.125, // 2 * 9 / 16
  margins = 128,
  minHeight = 560
) => {
  const height = browserHeight - margins;
  const width = browserWidth - margins;

  const modalWindowSize = {
    width: Math.min(width, height * aspectRatio),
    height: Math.min(height, width / aspectRatio),
  };

  if (modalWindowSize.height < minHeight) {
    modalWindowSize.height = minHeight;
    modalWindowSize.width = modalWindowSize.height * aspectRatio;
  }

  return modalWindowSize;
};

useMaxModalWindowSize.propTypes = {
  aspectRatio: PropTypes.number,
  browserHeight: PropTypes.number.isRequired,
  browserWidth: PropTypes.number.isRequired,
  margins: PropTypes.number,
  minHeight: PropTypes.number,
};

export default useMaxModalWindowSize;
