import React, { useEffect, useMemo, useState } from 'react';

import config from 'config';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import EllipseNavigationIcon from '~/icons/components/EllipseNavigationIcon';
import { Button } from '~/techstyle-shared/react-components';

import { useWindowSize } from '../../utils/useWindowSize';
import { useCarouselContext } from '../CarouselContext';

const NavigationIcon = styled(EllipseNavigationIcon)``;

const NavigationButton = styled(Button).attrs({
  variant: 'unstyled',
})`
  ${NavigationIcon} {
    color: ${({ theme: { colors } }) => colors.focusText};
  }
  [disabled] ${NavigationIcon} {
    color: ${({ theme: { colors } }) => colors.unfocusedSublabel};
  }

  ${({ inverse }) =>
    inverse &&
    `
    transform: scaleX(-1);
  `}
`;

const NavigationWrapper = styled.div`
  > :first-child {
    margin-right: 7px;
  }
`;

export default function CarouselNavigation({
  isAssetCarouselGrid = false,
  iconSize = 35,
  className,
}) {
  const { width: windowWidth } = useWindowSize();
  const { flickityPlayer: flkty } = useCarouselContext();
  const [flktyCellIndex, setFlktyCellIndex] = useState(0);
  const isFirstIndex = flktyCellIndex === 0 ?? false;
  const isLastIndex = flktyCellIndex === flkty?.slides.length - 1 ?? false;
  const cellsLength = flkty?.cells.length ?? 0;
  const itemSetting = isAssetCarouselGrid
    ? 'minAssetsInGrid'
    : 'minProductsInGrid';
  const hasMinimumProductsInGrid =
    cellsLength >
    config.get(`public.brand.carouselGridSettings.${itemSetting}`);
  const hasMinimumProductsInCells = cellsLength > flkty?.options?.groupCells;
  const hasMinimumProducts =
    hasMinimumProductsInGrid && hasMinimumProductsInCells;
  const allCellsAreShowing = useMemo(
    () =>
      !!windowWidth &&
      (flkty?.viewport?.clientWidth || 0) >=
        (flkty?.cells || []).reduce(
          (prev, acc) => prev + (acc?.size?.outerWidth || 0),
          0
        ),
    [flkty, windowWidth]
  );

  useEffect(() => {
    if (flkty) {
      flkty.on('change', (index) => {
        setFlktyCellIndex(index);
      });
    }
  }, [flkty]);

  const handleFlickityStep = (step) => {
    if (step === 'previous') {
      flkty.previous();
    } else {
      flkty.next();
    }
  };

  const handlePreviousProducts = () => {
    handleFlickityStep('previous');
  };

  const handleNextProducts = () => {
    handleFlickityStep('next');
  };

  if (allCellsAreShowing) {
    return null;
  }

  return (
    <div className={className}>
      {hasMinimumProducts ? (
        <NavigationWrapper>
          <NavigationButton
            onClick={handlePreviousProducts}
            disabled={isFirstIndex}
          >
            <NavigationIcon width={iconSize} height={iconSize} />
          </NavigationButton>
          <NavigationButton
            onClick={handleNextProducts}
            disabled={isLastIndex}
            inverse
          >
            <NavigationIcon width={iconSize} height={iconSize} />
          </NavigationButton>
        </NavigationWrapper>
      ) : null}
    </div>
  );
}

CarouselNavigation.propTypes = {
  className: PropTypes.string,
  iconSize: PropTypes.number,
  isAssetCarouselGrid: PropTypes.bool,
};
