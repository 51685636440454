import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { desktop, mobile } from '~/techstyle-shared/react-components';

import { useVideoCarouselContext } from '../../utils/VideoCarouselContext';

const LegalDisclaimerBlock = styled.div`
  font-size: 12px;
  width: 100%;

  ${desktop`
    ${({ $isOneSlide, $isOnPDP, $isWithGrayBackground, theme: { sizes } }) => {
      let topMargin;
      if ($isOnPDP) {
        topMargin = $isOneSlide && !$isWithGrayBackground ? sizes.lg - 5 : 0;
      } else {
        // for the Homepage
        topMargin =
          $isOneSlide && !$isWithGrayBackground ? sizes.lg + 5 : sizes.xxxs + 1;
      }

      return `
        margin: ${topMargin}px 0 ${sizes.lg - 7}px;
        ${$isOneSlide ? 'text-align: center;' : `padding-left: ${sizes.lg}px;`}
      `;
    }}
  `}

  ${mobile`
    ${({ $isOneSlide, $isOnPDP, theme: { sizes } }) => {
      let topMargin;
      if ($isOnPDP) {
        topMargin = $isOneSlide ? sizes.md - 2 : 0;
      } else {
        // for the Homepage
        topMargin = $isOneSlide ? sizes.lg - 3 : 0;
      }

      return `
        margin: ${topMargin}px 0 ${sizes.xxs - 2}px;
        ${
          $isOnPDP || $isOneSlide
            ? 'text-align: center;'
            : `padding-left: ${sizes.sm}px;`
        }
      `;
    }}
  `}
`;

export default function LegalDisclaimer({ isOneSlide }) {
  const { legalDisclaimer, isOnPDP, isWithGrayBackground } =
    useVideoCarouselContext();

  if (!legalDisclaimer) {
    return null;
  }

  return (
    <LegalDisclaimerBlock
      $isWithGrayBackground={isWithGrayBackground}
      $isOnPDP={isOnPDP}
      $isOneSlide={isOneSlide}
    >
      {legalDisclaimer}
    </LegalDisclaimerBlock>
  );
}

LegalDisclaimer.propTypes = {
  isOneSlide: PropTypes.bool,
};
