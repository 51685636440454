/**
 * @function convertStringsToInts
 * @description Temporary util to convert data coming back from Constructor in an array
 * as strings to ints. In the next phase, the Constructor response will be updated to
 * provide the entries as ints and this util should then be deprecated.
 * @param {Array} arr The containing array of entries to be converted from strings
 * to ints
 * @returns {Array} Array of ints
 */
export function convertStringsToInts(arr) {
  if (Array.isArray(arr)) {
    return arr.map((entry) => {
      return typeof entry === 'string' ? parseInt(entry) : entry;
    });
  }

  return [];
}
