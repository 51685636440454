import React from 'react';

import config from 'config';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import {
  promoPriceHiddenPromoTypes,
  promoPriceNoStrikeThrough,
} from '~/shared/constants/products';
import { SecondarySites } from '~/shared/constants/secondarySites';
import { getColorFromAlias } from '~/shared/utils/getColorFromAlias';
import { getPromoPricingActiveAsset } from '~/shared/utils/getPromoPricingActiveAsset';
import useSecondarySite from '~/shared/utils/useSecondarySite';
import { useMembership } from '~/techstyle-shared/react-accounts';
import { useAsset } from '~/techstyle-shared/react-assets';
import {
  ProductPrice as BaseProductPrice,
  useProductContext,
} from '~/techstyle-shared/react-components';
import { Currency, FormattedMessage } from '~/techstyle-shared/react-intl';
import { useSession } from '~/techstyle-shared/redux-core';

import featureNames from '../../constants/featureFlags';
import { getPromoPricingUIAssetContainerName } from '../../utils/promoPricingUI';
import useEMPPricing from '../../utils/useEMPPricing';
import useGshop from '../../utils/useGshop';
import useHasFeature from '../../utils/useHasFeature';
import MemberCreditCallout from '../MemberCreditCallout';
import TypedText from '../TypedText';

const PromoMessage = styled.span`
  ${({ promoMessageStyles }) => promoMessageStyles};
  ${({ theme }) => theme.pdpStyles.upsellPromos};
  ${({ theme }) => theme.gridCellStyles.promoMessage};
`;

const PromoLabelFromCms = styled.span`
  ${({ theme }) => theme.promoMessage.overrideMarkupStylesFromCms};
`;

const ProductColor = styled(TypedText).attrs({
  type: 'header',
  as: 'p',
  size: '12',
})`
  line-height: 18px;
`;

const defaultPriceStyle = css`
  ${({ theme }) => theme.gridCellStyles.defaultPriceStyle}
`;

const vipPriceStyle = css`
  ${({ theme }) => theme.gridCellStyles.vipPriceStyles}
`;

const priceStyleFavorites = css`
  color: ${({ theme }) => theme.colors.textEmphasized};
  font-size: 16px;
  line-height: 1;
`;

const PriceContentWrapper = styled.div`
  ${({ theme }) => theme.gridCellStyles.priceContent}
  ${({ priceContentStyles }) => priceContentStyles};
`;

const ProductPrice = styled(BaseProductPrice)`
  font-size: 14px;
  font-style: normal;
  letter-spacing: ${-0.4 / 16}em;
  ${({ $productPriceComponentStyles }) => $productPriceComponentStyles};
`;

const defaultPromoPriceStyle = css`
  ${({ theme }) => theme.gridCellStyles.defaultPromoPriceStyle}
`;

const PriceOffer = styled.span`
  ${({ theme }) => theme.gridCellStyles.defaultPromoPriceStyle}
`;

const PriceOfferOr = styled.span`
  color: ${({ theme }) => theme.colors.priceOfferOr};
`;

const DynamicFormattedMessage = FormattedMessage;

const PriceContentLoadingPlaceholder = styled.div`
  height: 20px;
`;

const OutletProductPrice = styled(Currency)`
  margin-right: 5px;
  font-size: 16px;
  font-weight: 600;
`;

function PriceContent({ product, priceContentStyles, children }) {
  // using retail price to determine whether pricing has loaded. This definition may need to change in the future
  const isPricingLoaded = Boolean(product?.retailUnitPrice);
  return (
    <PriceContentWrapper priceContentStyles={priceContentStyles}>
      {isPricingLoaded ? children : <PriceContentLoadingPlaceholder />}
    </PriceContentWrapper>
  );
}

PriceContent.propTypes = {
  children: PropTypes.node,
  priceContentStyles: PropTypes.any,
  product: PropTypes.object,
};

function ProductCellPriceDetails({
  product: productFromProps,
  priceStyle: priceStyleFromProps,
  promoPriceStyle: promoPriceStyleFromProps,
  promoMessageStyles,
  priceContentStyles,
  productPriceComponentStyles,
  favorites,
}) {
  const { isLoggedIn } = useSession();
  const productFromContext = useProductContext();
  const product = productFromProps || productFromContext;
  const { promoCode, promoLabel, promoTypeId } = product?.promos || {};
  const hasPromo = Boolean(promoCode) && typeof promoCode === 'string';
  const { isVip } = useMembership();
  const displayRetailPrice = useHasFeature(featureNames.RETAILPRICETEST);
  const showPromoPrice = hasPromo
    ? !promoPriceHiddenPromoTypes.has(promoTypeId)
    : undefined;
  const showStrikeThrough = !promoPriceNoStrikeThrough.has(promoTypeId);
  const { isTokenOnly, showMemberCredits, tokenValue } = useEMPPricing(product);
  const secondarySite = useSecondarySite();

  const promoPricingConfig = config.get('public.brand.promoPricingUI');
  const isPrioritizedEnabled =
    useHasFeature(featureNames.PRIORITIZED_PROMOS) &&
    promoPricingConfig?.showPromoPricingOffer;
  const offerLabelContainerName = getPromoPricingUIAssetContainerName(
    promoPricingConfig,
    isVip
  );
  const assetContainerOfferLabel = useAsset(offerLabelContainerName);

  const promoPricingActiveAsset = getPromoPricingActiveAsset({
    isPrioritizedEnabled,
    assetContainerOfferLabel,
    product,
  });

  const { isGshopActive } = useGshop();
  const isPromoPricingActive = Boolean(promoPricingActiveAsset);
  const gridLabel = promoPricingActiveAsset?.options?.customVars?.gridLabel;
  const htmlText = promoPricingActiveAsset?.options?.htmlText;

  const priceStyle = priceStyleFromProps || defaultPriceStyle;
  const promoPriceStyle = favorites
    ? priceStyleFavorites
    : promoPriceStyleFromProps || defaultPromoPriceStyle;

  const color = product.color || getColorFromAlias(product.alias);

  const showVipPrice = !displayRetailPrice || isGshopActive;

  if (secondarySite === SecondarySites.OUTLET) {
    const outletPrice = !isNaN(product.outletUnitPrice)
      ? product.outletUnitPrice
      : product.warehouseUnitPrice;

    return (
      <PriceContent product={product} priceContentStyles={priceContentStyles}>
        <OutletProductPrice
          amount={outletPrice}
          data-autotag="product-outlet-price"
        />
        <ProductPrice
          {...{
            showPromoPrice: false,
            strikeRegularPrice: true,
          }}
          priceStyle={priceStyle}
          showRetailPrice
          showVipPrice={false}
          $productPriceComponentStyles={productPriceComponentStyles}
        />
      </PriceContent>
    );
  }

  return (
    <>
      {isTokenOnly ? (
        <>
          <PromoMessage promoMessageStyles={promoMessageStyles}>
            <FormattedMessage
              id="grid.token_only_promo"
              defaultMessage="Member Credit Only"
            />
          </PromoMessage>
          <PriceContent product={product}>
            <MemberCreditCallout
              data-autotag="grid-credit-price"
              isTokenOnly
              product={product}
              tokenValue={tokenValue}
            />
          </PriceContent>
        </>
      ) : (
        <>
          {hasPromo && (
            <PromoMessage promoMessageStyles={promoMessageStyles}>
              {isPromoPricingActive ? (
                <PromoLabelFromCms
                  dangerouslySetInnerHTML={{ __html: htmlText }}
                />
              ) : (
                <DynamicFormattedMessage
                  id={`grid.callout_${promoCode.toLowerCase()}`}
                  defaultMessage={promoLabel}
                />
              )}
            </PromoMessage>
          )}
          <PriceContent
            product={product}
            priceContentStyles={priceContentStyles}
          >
            {favorites && color && (
              <ProductColor>
                <FormattedMessage
                  id="grid.color"
                  defaultMessage="Color: {color}"
                  values={{ color }}
                />
              </ProductColor>
            )}
            <ProductPrice
              {...(hasPromo
                ? {
                    promoPriceStyle,
                    showPromoPrice,
                    strikeRegularPrice: showStrikeThrough,
                  }
                : {
                    showPromoPrice: false,
                  })}
              priceStyle={
                !hasPromo && showVipPrice ? vipPriceStyle : priceStyle
              }
              showRetailPrice={
                !isPromoPricingActive && displayRetailPrice && !isGshopActive
              }
              showVipPrice={showVipPrice}
              $productPriceComponentStyles={productPriceComponentStyles}
            />{' '}
            {isPromoPricingActive && (
              <>
                <PriceOfferOr>
                  <FormattedMessage
                    id="global_cta.outfit_credit_or"
                    defaultMessage="or"
                  />{' '}
                </PriceOfferOr>
                <PriceOffer>{gridLabel}</PriceOffer>
              </>
            )}
            {isLoggedIn && showMemberCredits && (
              <MemberCreditCallout
                data-autotag="grid-credit-price"
                product={product}
                tokenValue={tokenValue}
              />
            )}
          </PriceContent>
        </>
      )}
    </>
  );
}

ProductCellPriceDetails.propTypes = {
  favorites: PropTypes.bool,
  priceContentStyles: PropTypes.any,
  priceStyle: PropTypes.any,
  product: PropTypes.object,
  productPriceComponentStyles: PropTypes.any,
  promoMessageStyles: PropTypes.any,
  promoPriceStyle: PropTypes.any,
};

export default ProductCellPriceDetails;
