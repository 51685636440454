/**
 * @function getGroupedImages
 * @description Groups images into arrays based on the device type (mobile or desktop).
 *
 * @param {Object} params - The parameters object.
 * @param {Array} params.imageSets - An array of image objects.
 * @param {boolean} params.isMobile - A flag indicating if the device is mobile.
 * @returns {Array} A nested array of grouped images. For mobile, it returns a single array with one image. For desktop, it returns an array with two arrays, each containing up to two images.
 */
export function getGroupedImages({ imageSets, isMobile }) {
  // There is no image set.
  if (!imageSets.length) {
    return [];
  }

  // There is only one image for mobile with no hover.
  if (isMobile) {
    const productImage = imageSets[0];
    return [[productImage]];
  }

  // Make an array of array of images. The first array is the first 2 images of the image set, and the second array is the last 2 images of the image set.
  const groupedImages = imageSets.slice(0, 4).reduce(
    (acc, img, index) => {
      if (index < 2) {
        acc[0].push(img);
      } else {
        acc[1].push(img);
      }
      return acc;
    },
    [[], []]
  );

  return groupedImages;
}
