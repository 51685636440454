import React from 'react';

import PropTypes from 'prop-types';

import CarouselContext from '~/shared/components/CarouselContext';
import VideoCarousel from '~/shoppableVideo/components/VideoCarousel';
import { VideoCarouselProvider } from '~/shoppableVideo/utils/VideoCarouselContext';

export default function BuilderVideoCarousel({
  videos = [],
  title,
  legalDisclaimer,
  builderState,
}) {
  const isOnPDP = !!builderState.context.isOnPDP;
  const isThumbnailsMode = !!builderState.context.isThumbnailsMode;
  const hasGrayBackground = !!builderState.content.data.grayBackground;
  const hideProductNameInTitle =
    !!builderState.content.data.hideProductNameInTitle;

  return (
    <VideoCarouselProvider
      isOnPDP={isOnPDP}
      isThumbnailsMode={isThumbnailsMode}
      hideProductNameInTitle={hideProductNameInTitle}
      subTitle={builderState.context.subTitle}
      hasGrayBackground={hasGrayBackground}
      legalDisclaimer={legalDisclaimer}
    >
      <CarouselContext>
        <VideoCarousel videos={videos} title={title} />
      </CarouselContext>
    </VideoCarouselProvider>
  );
}

BuilderVideoCarousel.propTypes = {
  builderState: PropTypes.shape({
    content: PropTypes.shape({
      data: PropTypes.shape({
        hideProductNameInTitle: PropTypes.bool,
        grayBackground: PropTypes.bool,
      }),
    }),
    context: PropTypes.shape({
      isOnPDP: PropTypes.bool,
      isThumbnailsMode: PropTypes.bool,
      subTitle: PropTypes.string,
    }),
  }),
  legalDisclaimer: PropTypes.string,
  title: PropTypes.string,
  videos: PropTypes.array.isRequired,
};
