import { gql } from '~/techstyle-shared/react-graphql';

export const FEED_QUERY = gql`
  query UnseenVideoFeed(
    $pagination: Paginated!
    $user: UserInfo!
    $excludeVideoIds: [String!]
  ) {
    unseenVideoFeed(
      pagination: $pagination
      user: $user
      excludeVideoIds: $excludeVideoIds
    ) {
      videos {
        id
        video {
          url
          description
          avcUrl
          thumbnailUrl
          gender
        }
        createdAt
        updatedAt
        products {
          masterProductId
          images {
            default {
              sizes {
                url
              }
            }
          }
        }
      }
      pageInfo {
        hasNext
      }
    }
  }
`;

export const VIDEO_BY_ID = gql`
  query VideoById($id: String!) {
    video(id: $id) {
      video {
        url
        thumbnail_url
      }
      liked
      author {
        name
        thumbnail_url
      }
      products {
        masterProductId
      }
      createdAt
    }
  }
`;

export const TRACK_VIDEO_VIEW = gql`
  mutation TrackVideoView($videoView: VideoViewed) {
    trackVideoView(videoView: $videoView) {
      createdAt
      userId
      videoId
    }
  }
`;

export const GET_SHOPPABLE_VIDEO_DATA_BY_MASTER_PRODUCT_ID = gql`
  query FLShoppableVideoGetProductDataByMasterProductIds(
    $productQuery: ProductQuery
    $size: Int
    $from: Int
  ) {
    productListing(productQuery: $productQuery, from: $from, size: $size) {
      products {
        storeGroupId
        images {
          default {
            sizes {
              url
              height
              width
            }
          }
        }
        masterProductId
        label
      }
    }
  }
`;
export const GET_PRICING_BY_MASTER_PRODUCT_ID = gql`
  query ProductByProductId($productId: Int) {
    productByProductId(productId: $productId) {
      masterProductId
      permalink
      pricing {
        retailUnitPrice: regularPrice
        saleUnitPrice: salePrice
        defaultUnitPrice: vipPrice
        promo {
          promoCode: code
          promoLabel: label
          promoPrice: price
          promoTypeId: typeId
          displayOnPDP
        }
      }
      ... on BundleProduct {
        tags {
          id
        }
      }
      ... on IndividualProduct {
        tags {
          id
        }
      }
    }
  }
`;
