import * as Sentry from '@sentry/react';

export const captureVideoError = ({
  error,
  label,
  data: { video, isFullscreen = false },
}) => {
  if (!video) {
    return;
  }
  const scope = new Sentry.Scope();
  scope.setTag('label', label);
  scope.setTag('video_id', video.id);
  scope.setTag('vimeo_id', video.vimeoId);
  scope.setTag('is_fullscreen', isFullscreen);
  Sentry.captureException(error, scope);
};
