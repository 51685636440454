import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import RotateDeviceIcon from '~/icons/fabletics/RotateDevice';
import { mobilexsLandscape } from '~/shoppableVideo/utils';
import { useMounted, useTheme } from '~/techstyle-shared/react-components';

const RotateYourDeviceBanner = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 80vw;
  height: 60px;
  background: rgba(0, 0, 0, 0.7);
  color: ${({ theme }) => theme.colors.palette.white};
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: ${({ theme }) => theme.zIndexes.tooltip};
  gap: 26px;
  ${mobilexsLandscape`
    display: flex;
  `}
`;

export default function RotateDeviceBanner({ text, ...rest }) {
  const isMounted = useMounted();
  const theme = useTheme();
  if (!isMounted) {
    return null;
  }

  return (
    <RotateYourDeviceBanner {...rest}>
      <RotateDeviceIcon
        primaryColor="white"
        secondaryColor={theme.colors.palette.deepBrown}
      />
      {text}
    </RotateYourDeviceBanner>
  );
}

RotateDeviceBanner.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
