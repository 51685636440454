import React from 'react';

import PropTypes from 'prop-types';

export default function VolumeXmarkIcon({
  color = 'currentColor',
  size = '24',
  ...rest
}) {
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M13.1328 3.36426C13.6016 3.59863 13.8359 3.9502 13.875 4.49707V19.4971C13.8359 20.0439 13.6016 20.3955 13.1328 20.6299C12.9766 20.708 12.7812 20.7471 12.625 20.7471C12.3125 20.7861 12.0391 20.6299 11.8047 20.3174L6.53125 15.6299H3.25C2.70312 15.6299 2.27344 15.4736 1.92188 15.1221C1.57031 14.8096 1.375 14.3408 1.375 13.7939V10.083C1.375 9.5752 1.57031 9.14551 1.92188 8.79395C2.27344 8.48145 2.70312 8.28613 3.25 8.24707H6.53125L11.8047 3.55957C12.0391 3.36426 12.3125 3.24707 12.625 3.24707C12.7812 3.24707 12.9766 3.28613 13.1328 3.36426ZM12 18.0908V5.90332L7.23438 10.1611H3.25V13.8721H7.23438L12 18.0908Z"
        fill={color}
      />
      <path
        d="M17 14L19 12M19 12L21 10M19 12L17 10M19 12L21 14"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

VolumeXmarkIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
