import { convertStringsToInts } from './convertStringsToInts';

export function getIsPromoActive(product, assetOfferLabel) {
  const categoryID = assetOfferLabel
    ? assetOfferLabel.options.customVars.categoryID
    : null;
  const excludedCatID = assetOfferLabel
    ? assetOfferLabel.options.customVars.excludeCatID
    : null;
  const fplID = assetOfferLabel
    ? assetOfferLabel.options.customVars.fplID
    : null;
  const excludedFplID = assetOfferLabel
    ? assetOfferLabel.options.customVars.excludeFplID
    : null;

  const featuredProductLocationIdList =
    convertStringsToInts(product?.featuredProductLocationIdList) || [];
  const productCategoryIdList =
    convertStringsToInts(product?.productCategoryIdList) || [];

  return (
    (productCategoryIdList.includes(categoryID) ||
      featuredProductLocationIdList.includes(fplID)) &&
    !(
      productCategoryIdList.includes(excludedCatID) ||
      featuredProductLocationIdList.includes(excludedFplID)
    )
  );
}
