import React, { forwardRef, useEffect, useState } from 'react';

import 'intersection-observer';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import { use100vh } from 'react-div-100vh';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';

import featureNames from '~/shared/constants/featureFlags';
import useHasFeature from '~/shared/utils/useHasFeature';
import {
  trackVideoPlaybackCompleted,
  trackVideoPlaybackStarted,
} from '~/shoppableVideo/actions';
import { largerThanMobileXs } from '~/shoppableVideo/utils/';
import useForceHLS from '~/shoppableVideo/utils/useForceHLS';
import { useVideoCarouselContext } from '~/shoppableVideo/utils/VideoCarouselContext';
import { desktop, Video } from '~/techstyle-shared/react-components';

import HLSVideoPlayer from '../HLSVideoPlayer';
import VideoAuthor from '../VideoAuthor';
import VideoContainer from '../VideoContainer';
import VideoDescription from '../VideoDescription';
import VideoModalProductCell from '../VideoModalProductCell';

const Wrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.palette.white};
`;

const VideoCell = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;

  ${({ $desktopView }) =>
    $desktopView &&
    css`
      ${desktop`
        width: 50%;
      `}
    `}
  }
`;

const StyledHLSVideoPlayer = styled(HLSVideoPlayer)`
  & video {
    object-fit: cover;
    background-image: ${({ $posterImageUrl }) => `url(${$posterImageUrl})`};
    background-size: cover;
    background-position: center;
  }
  ${largerThanMobileXs`
    width: 100% !important;
  `}
`;

const ImagePoster = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

/**
 * The VideoWithProducts component is used to display a video with the first item of the product list.
 * Nota Bene! It is used ONLY in the modal with a full-width video for the VideoCarousel feature.
 */
const VideoWithProduct = forwardRef(
  (
    {
      video,
      onPlaybackStarted,
      onIntersectedViewport = noop,
      posterMode = false,
      isLandscapeMobile = false,
      isPlaying: isPlayingFromProps = true,
      className,
      ...videoProps
    },
    playerRef
  ) => {
    const [isMuted, setIsMuted] = useState(true);
    const [isPlaying, setIsPlaying] = useState(true);
    const [playingInfo, setPlayingInfo] = useState({
      played: 0,
      loaded: 0,
      duration: 0,
      remaining: 0,
    });
    const [trackedEvent, setTrackingEvent] = useState({
      videoStarted: false,
      videoCompleted: false,
    });
    const shouldPlay = isPlayingFromProps && isPlaying && !posterMode;
    const videoObj = video.video;
    const dispatch = useDispatch();
    const browserHeight = use100vh();
    const pdpVideoDescriptionsEnabled = useHasFeature(
      featureNames.ENABLE_PDP_VIDEO_DESCRIPTIONS
    );
    const { isOnPDP } = useVideoCarouselContext();

    const handleContainerClick = () => {
      setIsPlaying((prev) => !prev);
    };

    const handleOnPlay = () => {
      setIsPlaying(true);
      if (onPlaybackStarted) {
        onPlaybackStarted(video, playingInfo);
      }
    };

    const trackingEventPayload = {
      video_name: videoObj.name || videoObj.id,
      ...(video.author && { creator_name: video.author.name }),
      content_asset_id: videoObj.id,
      vimeo_url: videoObj.url,
      video_player: 'html',
      category: 'Video Carousel',
      label: 'modal',
      full_screen: true,
    };

    const handleProgress = (videoState) => {
      setPlayingInfo((prev) => ({ ...prev, ...videoState }));
      if (!trackedEvent.videoStarted && videoState.played > 0) {
        setTrackingEvent((prev) => ({ ...prev, videoStarted: true }));
        dispatch(trackVideoPlaybackStarted(trackingEventPayload));
      }
      if (!trackedEvent.videoCompleted && videoState.played > 0.9) {
        setTrackingEvent((prev) => ({ ...prev, videoCompleted: true }));
        dispatch(trackVideoPlaybackCompleted(trackingEventPayload));
      }
    };

    useEffect(() => {
      if (!posterMode) {
        // New video, play it.
        setIsPlaying(true);
      }
    }, [video.id, posterMode]);

    const forceHLS = useForceHLS();

    return (
      <Wrapper className={className}>
        <VideoCell $desktopView={!isLandscapeMobile}>
          <VideoContainer
            posterMode={posterMode}
            height={browserHeight}
            id={video.id}
            isPlaying={isPlaying}
            playingInfo={playingInfo}
            isMuted={isMuted}
            onVolumeControlClick={setIsMuted}
            onContainerClick={handleContainerClick}
            disabled={isLandscapeMobile}
          >
            {posterMode ? (
              <ImagePoster
                src={videoObj?.thumbnailUrl}
                alt={videoObj?.description}
              />
            ) : (
              <StyledHLSVideoPlayer
                video={videoObj}
                forceHLS={forceHLS}
                isPlaying={shouldPlay}
                onPlay={handleOnPlay}
                onPause={() => setIsPlaying(false)}
                isMuted={isMuted}
                onProgress={handleProgress}
                onDuration={(duration) =>
                  setPlayingInfo((prev) => ({ ...prev, duration }))
                }
                wrapperStyle={{ height: '100%' }}
                $posterImageUrl={videoObj?.thumbnailUrl}
                ref={playerRef}
                {...videoProps}
              />
            )}
            {!isLandscapeMobile && video.author && (
              <VideoAuthor author={video.author} inModal />
            )}
            {video.description && pdpVideoDescriptionsEnabled && isOnPDP && (
              <VideoDescription description={video.description} />
            )}
          </VideoContainer>
        </VideoCell>
        <VideoModalProductCell
          video={video}
          isLandscapeMobile={isLandscapeMobile}
        />
      </Wrapper>
    );
  }
);

VideoWithProduct.displayName = 'VideoWithProduct';

VideoWithProduct.propTypes = {
  className: PropTypes.string,
  isLandscapeMobile: PropTypes.bool,
  isPlaying: PropTypes.bool,
  onIntersectedViewport: PropTypes.func,
  onPlaybackStarted: PropTypes.func,
  shouldShow: PropTypes.bool,
  video: PropTypes.object.isRequired,
  ...Video.propTypes,
};

export default VideoWithProduct;
