import {
  MembershipType,
  useMembership,
} from '~/techstyle-shared/react-accounts';

export default function useEMPMember() {
  const { membershipTypeId } = useMembership();
  const isEMP =
    membershipTypeId === MembershipType.TOKEN ||
    membershipTypeId === MembershipType.TOKEN_ANNIVERSARY;
  return {
    isEMP: Boolean(isEMP),
  };
}
