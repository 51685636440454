import React from 'react';

import PropTypes from 'prop-types';

export default function RotateDevice({
  primaryColor,
  secondaryColor,
  ...props
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="38"
      viewBox="0 0 40 38"
      fill="none"
      {...props}
    >
      <path
        d="M3.94652 10.4034C4.25627 6.00968 7.83843 2.84635 14.7705 4.41734"
        stroke={primaryColor}
        strokeWidth="1.6523"
        strokeLinecap="round"
      />
      <path
        d="M14.7847 4.21629L12.2506 0.937412"
        stroke={primaryColor}
        strokeWidth="1.6523"
        strokeLinecap="round"
      />
      <path
        d="M14.8513 4.33675L11.8827 7.2281"
        stroke={primaryColor}
        strokeWidth="1.6523"
        strokeLinecap="round"
      />
      <path
        d="M2.49706 16.0203L30.71 16.0202C31.5231 16.0202 32.1822 16.6794 32.1822 17.4924V31.7194C32.1822 32.5324 31.5231 33.1915 30.71 33.1915L2.49706 33.1916C1.684 33.1916 1.02488 32.5325 1.02488 31.7194V17.4925C1.02488 16.6794 1.68399 16.0203 2.49706 16.0203Z"
        stroke={primaryColor}
        strokeWidth="1.67525"
      />
      <rect
        x="19.017"
        y="1.22572"
        width="19.6842"
        height="33.2224"
        rx="2.51288"
        fill={secondaryColor}
        stroke={secondaryColor}
        strokeWidth="0.837626"
      />
      <g filter="url(#filter0_d_3506_8178)">
        <rect
          x="20.2734"
          y="2.48216"
          width="17.1713"
          height="30.7096"
          rx="1.47218"
          stroke={primaryColor}
          strokeWidth="1.67525"
          shapeRendering="crispEdges"
        />
      </g>
      <rect
        x="24.7009"
        y="1.64453"
        width="8.31675"
        height="3.20286"
        rx="1.60143"
        fill={primaryColor}
      />
      <rect
        x="0.187256"
        y="28.7637"
        width="8.31675"
        height="3.20286"
        rx="1.60143"
        transform="rotate(-90 0.187256 28.7637)"
        fill={primaryColor}
      />
      <path
        d="M26.7357 14.7526C26.6065 14.673 26.4444 14.6696 26.3119 14.7436C26.1795 14.8177 26.0974 14.9575 26.0974 15.1092V21.8103C26.0974 21.962 26.1795 22.1018 26.3119 22.1759C26.4444 22.2499 26.6065 22.2465 26.7357 22.1669L32.1803 18.8164C32.3042 18.7402 32.3796 18.6052 32.3796 18.4597C32.3796 18.3143 32.3042 18.1793 32.1803 18.1031L26.7357 14.7526Z"
        fill={primaryColor}
      />
      <defs>
        <filter
          id="filter0_d_3506_8178"
          x="17.7605"
          y="1.64453"
          width="22.1972"
          height="35.7353"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.67525" />
          <feGaussianBlur stdDeviation="0.837626" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3506_8178"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3506_8178"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

RotateDevice.propTypes = {
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
};
