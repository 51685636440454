import React, { useCallback, useRef, useState } from 'react';

import merge from 'lodash/merge';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { trackVideoPlaybackStarted } from '../../actions';
import HLSVideoPlayer from '../HLSVideoPlayer';

// Max seconds to be played before the video starts again ("preview" experience)
const MAX_PLAYABLE_SECONDS = 15;

const StyledHLSVideoPlayer = styled(HLSVideoPlayer)`
  position: relative;
  & video {
    width: 100%;
    height: auto;
    max-height: 100%;
    object-fit: cover;
    ${({ $thumbnailUrl }) =>
      $thumbnailUrl && `background-image: url(${$thumbnailUrl});`}
  }
`;

const VideoPreview = ({
  video,
  author,
  css,
  wrapperStyle,
  isPlaying = false,
  trackingCategory = 'Video Carousel',
  trackingLabel = 'feed page',
  onError = () => {},
  ...rest
}) => {
  const playerRef = useRef();
  const hasTrackedRef = useRef(false);
  const [isVisible, setIsVisible] = useState(false);
  const [preload, setPreload] = useState('none');
  const dispatch = useDispatch();

  const handleProgress = ({ playedSeconds }) => {
    if (playedSeconds >= MAX_PLAYABLE_SECONDS) {
      playerRef.current.seekTo(0, 'seconds');
    }
  };

  const trackingEventPayload = {
    video_name: video.name || video.id,
    ...(author && { creator_name: author.name }),
    content_asset_id: video.id,
    vimeo_url: video.url,
    video_player: 'html',
    category: trackingCategory,
    label: trackingLabel,
    full_screen: false,
  };
  const handleVideoInView = useCallback(
    (event) => {
      const inViewPort = event.isIntersecting;
      setIsVisible(inViewPort);
      if (inViewPort && !hasTrackedRef.current) {
        dispatch(trackVideoPlaybackStarted(trackingEventPayload));
        hasTrackedRef.current = true;
      }
      setPreload(event.intersectionRatio === 0 ? 'none' : 'auto');
    },
    [dispatch, trackingEventPayload]
  );

  if (!video) {
    return null;
  }

  return (
    <StyledHLSVideoPlayer
      ref={playerRef}
      video={video}
      isPlaying={isPlaying && isVisible}
      isMuted
      onProgress={handleProgress}
      height="100%"
      width="100%"
      isVisible={isVisible}
      wrapperStyle={merge(
        {},
        {
          height: '100%',
          '&:before': {
            paddingTop: 0,
          },
        },
        wrapperStyle
      )}
      threshold={0.45}
      onIntersectionChange={handleVideoInView}
      preload={preload}
      onError={(error) => {
        if (error === 'hlsError') {
          onError(error);
          const player = playerRef.current.getInternalPlayer();
          if (!player) {
            return;
          }
          player.src = '';
          player.load();
          player.src = video.avcUrl;
          player.load();
        }
      }}
      {...rest}
    />
  );
};

VideoPreview.propTypes = {
  author: PropTypes.object,
  css: PropTypes.string,
  trackingCategory: PropTypes.string,
  trackingLabel: PropTypes.string,
  video: PropTypes.object.isRequired,
  wrapperStyle: PropTypes.object,
  ...HLSVideoPlayer.propTypes,
};

export default VideoPreview;
