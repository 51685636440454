import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useVideoCarouselContext } from '~/shoppableVideo/utils/VideoCarouselContext';
import {
  desktop,
  mobile,
  SkeletonPulse as SkeletonAnimation, // TODO: It will be updated with the new loading animation.
} from '~/techstyle-shared/react-components';

import VideoCarouselProductInfoSkeleton from '../VideoCarouselProductInfoSkeleton';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 0
    ${({ $isThumbnailsCarousel, theme: { videoCarousel: carousel } }) =>
      !$isThumbnailsCarousel && `${carousel.padding}px ${carousel.padding}px`};

  ${mobile`
    ${({ $isThumbnailsCarousel, theme: { sizes } }) =>
      $isThumbnailsCarousel
        ? `
        padding: 0 ${sizes.md}px;
      `
        : `
        padding-left: ${sizes.sm}px;
      `}
  `}
`;

const CarouselLoader = styled.div`
  display: flex;
  height: auto;
  align-items: flex-start;
  position: relative;
  overflow: hidden;

  gap: ${({ carouselTheme: { gap } }) => gap.mobile}px;
  ${desktop`
    gap: ${({ carouselTheme: { gap } }) => gap.desktop}px;
  `}
`;

const CellSkeleton = styled.div`
  display: block;
  flex-shrink: 0;
  position: relative;
  height: ${({ carouselTheme: { video } }) => video.mobile.height}px;
  width: ${({ carouselTheme: { video } }) => video.mobile.width}px;
  border-radius: ${({ carouselTheme: { video } }) => video.radius}px;
  overflow: hidden;

  ${desktop`
    height: ${({ carouselTheme: { video } }) => video.desktop.height}px;
    width: ${({ carouselTheme: { video } }) => video.desktop.width}px;
  `}
`;

const VideoSkeleton = styled(SkeletonAnimation)`
  height: 100%;
  width: 100%;
`;

export default function VideoCarouselLoadingSkeleton({ cellNumber = 4 }) {
  const { isOnPDP, isThumbnailsMode, carouselTheme } =
    useVideoCarouselContext();
  const isThumbnailsCarousel = isOnPDP && isThumbnailsMode;

  return (
    <Wrapper $isThumbnailsCarousel={isThumbnailsCarousel}>
      <CarouselLoader carouselTheme={carouselTheme}>
        {Array.from({ length: cellNumber }).map((_, index) => (
          <CellSkeleton key={index} carouselTheme={carouselTheme}>
            <VideoSkeleton />
            {!isOnPDP && <VideoCarouselProductInfoSkeleton />}
          </CellSkeleton>
        ))}
      </CarouselLoader>
    </Wrapper>
  );
}

VideoCarouselLoadingSkeleton.propTypes = {
  cellNumber: PropTypes.number,
};
