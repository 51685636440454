import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Carousel as BaseCarousel,
  desktop,
  mobile,
  useBreakpoint,
} from '~/techstyle-shared/react-components';

import { CAROUSEL_STYLING } from '../VideoCarousel';
import VideoCarouselLoadingSkeleton from '../VideoCarouselLoadingSkeleton';

export const VideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  width: var(--carousel-video-width);
  height: var(--carousel-video-height);
  border-radius: var(--carousel-video-border-radius);
`;

const OneSlideContainer = styled.div`
  display: flex;
  width: 100%;

  ${({ $styling, isMobile, theme: { sizes, videoCarousel: carousel } }) =>
    $styling !== CAROUSEL_STYLING.pdpThumbnails
      ? `
        gap: ${carousel.padding + 7}px;
        align-items: center;
        justify-content: center;
      `
      : `
        padding-left: ${isMobile ? sizes.md : 0}px;
        gap: ${
          isMobile
            ? carousel.thumbnailsCarousel.gap.mobile
            : carousel.thumbnailsCarousel.gap.desktop
        }px;
    `}

  ${VideoWrapper} {
    position: relative;
    width: auto;

    ${desktop`
      ${({ $styling, theme: { videoCarousel: carousel } }) =>
        $styling !== CAROUSEL_STYLING.pdpThumbnails &&
        `
          width: ${carousel.video.desktop.width}px;
      `}
    `}

    ${({
      $styling,
      isMobile,
      theme: {
        videoCarousel: { thumbnailsCarousel: carousel },
      },
    }) =>
      $styling === CAROUSEL_STYLING.pdpThumbnails &&
      `
        overflow: hidden;
        border-radius: ${carousel.video.radius}px;
        width: ${
          isMobile ? carousel.video.mobile.width : carousel.video.desktop.width
        }px;
        height: ${
          isMobile
            ? carousel.video.mobile.height
            : carousel.video.desktop.height
        }px;
    `}
`;

const FullWidthCarousel = styled(BaseCarousel)`
  max-height: 80vh;
  max-width: ${({ theme }) => theme.breakpoints.laptop.maxWidth}px;
  padding: 0 0
    ${({ theme: { videoCarousel: carousel } }) =>
      `${carousel.padding}px ${carousel.padding}px`};

  // With a HLS video inside of a flickity carousel, the height of the flickity-viewport has to be set very specific to preclude any issue.
  & .flickity-viewport {
    height: var(--carousel-height);
  }

  ${mobile`
    padding: ${({ $styling, theme: { sizes, videoCarousel: carousel } }) =>
      $styling === CAROUSEL_STYLING.pdpThumbnails
        ? `
          0 ${sizes.md}px;`
        : `
          0 0 ${carousel.padding}px ${sizes.xs}px;`}
  `}

  ${({
    $styling,
    theme: {
      videoCarousel: { thumbnailsCarousel: carousel },
    },
  }) =>
    $styling === CAROUSEL_STYLING.pdpThumbnails &&
    `
      padding: 0;
      height: auto;
  `};
`;

const Wrapper = styled.div`
  position: relative;
`;

export const FullWidthCarouselOrOneSlideComponent = ({
  children,
  carouselStyling,
  isOneSlide = false,
  isCarouselLoading = true,
  ...carouselProps
}) => {
  const { isMobile } = useBreakpoint();
  return (
    <Wrapper>
      {isOneSlide ? (
        <OneSlideContainer $styling={carouselStyling} isMobile={isMobile}>
          {children}
        </OneSlideContainer>
      ) : (
        <>
          <FullWidthCarousel $styling={carouselStyling} {...carouselProps}>
            {children}
          </FullWidthCarousel>
          {isCarouselLoading && <VideoCarouselLoadingSkeleton />}
        </>
      )}
    </Wrapper>
  );
};

FullWidthCarouselOrOneSlideComponent.propTypes = {
  carouselStyling: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isCarouselLoading: PropTypes.bool,
  isOneSlide: PropTypes.bool,
};
