import React, { memo, useCallback, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { useCarouselContext } from '~/shared/components/CarouselContext';
import CarouselNavigation from '~/shared/components/CarouselNavigation';
import featureNames from '~/shared/constants/featureFlags';
import useHasFeature from '~/shared/utils/useHasFeature';
import { captureVideoError } from '~/shoppableVideo/utils/captureVideoError';
import { useVideoRelatedProducts } from '~/shoppableVideo/utils/VideoCarousel/useVideoRelatedProducts';
import { useVideoCarouselContext } from '~/shoppableVideo/utils/VideoCarouselContext';
import {
  desktop,
  mobile,
  useTheme,
  Separator,
  useBreakpoint,
} from '~/techstyle-shared/react-components';
import { ProductListViewedProvider } from '~/techstyle-shared/react-products';

import {
  FullWidthCarouselOrOneSlideComponent,
  VideoWrapper,
} from '../FullWidthCarouselOrOneSlideComponent';
import LegalDisclaimer from '../LegalDisclaimer';
import ShadowShim from '../ShadowShim';
import VideoAuthor from '../VideoAuthor';
import VideoCarouselProductInfo from '../VideoCarouselProductInfo';
import VideoModal from '../VideoModal';
import VideoPreview from '../VideoPreview';

import { useVideoCarouselConfig, CAROUSEL_STYLING } from './utils';

const pdpThumbnailsCellStyle = css`
  height: 100%;
  ${desktop`
    width: ${({
      theme: {
        videoCarousel: { thumbnailsCarousel: carousel },
      },
    }) => carousel.video.desktop.width}px;
    margin-right: ${({
      theme: {
        videoCarousel: { thumbnailsCarousel: carousel },
      },
    }) => carousel.gap.desktop}px;

    &:last-child {
      margin-right: 0;
    }
  `}
  ${mobile`
    width: ${({
      theme: {
        videoCarousel: { thumbnailsCarousel: carousel },
      },
    }) => carousel.video.mobile.width}px;
    margin-right: ${({
      theme: {
        videoCarousel: { thumbnailsCarousel: carousel },
      },
    }) => carousel.gap.mobile}px;
  `}
`;
const noop = () => {};

const cellStyle = css`
  height: 100%;
  ${desktop`
    margin-right: ${({ theme: { videoCarousel: carousel } }) =>
      carousel.gap.desktop}px;
    width: ${({ theme: { videoCarousel: carousel } }) =>
      carousel.video.desktop.width}px;
    
    &:last-child {
      margin-right: 0;
    }
  `}
  ${mobile`
    margin-right: ${({ theme: { videoCarousel: carousel } }) =>
      carousel.gap.mobile}px;
    width: ${({ theme: { videoCarousel: carousel } }) =>
      carousel.video.mobile.width}px;
  `}
`;

const SubTitle = styled.span`
  display: block;
  font-size: 1.25rem;
  font-weight: 400;
  text-transform: capitalize;
  ${desktop`
    display: inline-block;
    margin-left: ${({ theme: { sizes } }) => sizes.sm - 1}px;
  `}
`;

const SectionTitle = styled.h3`
  color: ${({ theme }) => theme.colors.shoppableVideoCarouselTitle};
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  ${({ $isCentered }) =>
    $isCentered
      ? `
        flex-grow: 0;
        text-align: center;`
      : `
         flex-grow: 1;`}
  ${desktop`
    font-size: ${({ $isOnPDP, $styling }) => {
      switch ($styling) {
        case CAROUSEL_STYLING.pdpThumbnails:
          return '1rem';
        default:
          return $isOnPDP ? '1.75rem' : '2.25rem';
      }
    }};
    line-height: normal;
  `}
  ${mobile`
    font-size: ${({ $styling }) =>
      $styling === CAROUSEL_STYLING.pdpThumbnails ? '1rem' : '1.75rem'};
    line-height: 1.5;
  `}
`;

const getVideoCarouselWrapperStyles = ({
  variantStyles,
  isMobile,
  hasDescription,
}) => {
  const deviceType = isMobile ? 'mobile' : 'desktop';
  let carouselHeight = variantStyles.video[deviceType].height;
  if (hasDescription) {
    carouselHeight +=
      variantStyles.description.height[deviceType] +
      variantStyles.description.margin[deviceType];
  }
  return `
      --carousel-height: ${carouselHeight}px;
      --carousel-video-width: ${variantStyles.video[deviceType].width}px;
      --carousel-video-height: ${variantStyles.video[deviceType].height}px;
      --carousel-video-border-radius: ${variantStyles.video.radius}px;
      --carousel-cell-description-lines: ${variantStyles.description.lines[deviceType]};
      --carousel-cell-description-font-size: ${variantStyles.description.font.size[deviceType]};
      --carousel-cell-description-line-height: ${variantStyles.description.font.lineHeight[deviceType]};
      --carousel-cell-description-height: ${variantStyles.description.height[deviceType]}px;
      --carousel-cell-description-margin: ${variantStyles.description.margin[deviceType]}px;
    `;
};

// Wrappers

const FullWidthWrapper = styled.div`
  ${({ $styling, theme, $isMobile, $hasDescription }) =>
    getVideoCarouselWrapperStyles({
      variantStyles:
        $styling === CAROUSEL_STYLING.pdpThumbnails
          ? theme.videoCarousel.thumbnailsCarousel
          : theme.videoCarousel,
      isMobile: $isMobile,
      hasDescription: $hasDescription,
    })};
  width: 100%;
  ${({
    $isOneSlide,
    $hasGrayBackground,
    $styling,
    theme: { colors, sizes },
  }) => {
    if ($styling === CAROUSEL_STYLING.pdpThumbnails) {
      return `
        border-top: 1px solid ${colors.separator};
        padding-top: ${sizes.md - 2}px;
      `;
    } else if ($hasGrayBackground) {
      return `
        background-color: ${colors.shoppableVideoCarouselFullWidthWrapperBg};
        padding: ${$isOneSlide ? sizes.xs - 1 : sizes.xxs}px 0 ${sizes.md}px;

        button svg {
          circle {
            fill: none;
          }
        }
      `;
    }
  }}

  ${mobile`
    ${({ $hasGrayBackground, $styling, theme: { sizes } }) => {
      if ($styling === CAROUSEL_STYLING.pdpThumbnails) {
        return `
          padding-top: 0;
          border-top: none;
          margin-top: ${sizes.md - 2}px;
        `;
      } else if ($hasGrayBackground) {
        return `
          // The negative margin is to compensate the margin of a preceding block on a PDP.
          // And it would be complicated to adjust the margin of the preceding block as this component is optional.
          margin: -${sizes.lg}px 0;
          padding: ${sizes.lg - 2}px 0 ${sizes.lg - 4}px;
        `;
      }
    }}
  `}
`;

const ContainedWrapper = styled.div`
  max-width: ${({ theme }) => theme.breakpoints.laptop.maxWidth}px;
  margin-inline: auto;
`;

const StyledCarouselNavigation = styled(CarouselNavigation)``;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({ $styling, theme: { sizes, videoCarousel: carousel } }) =>
    $styling === CAROUSEL_STYLING.pdpThumbnails
      ? `
        2px 0 ${sizes.sm + 2}px;`
      : `
        0 0 ${carousel.padding - 8}px;`}
  padding: 0;

  ${StyledCarouselNavigation} {
    ${({ $styling, theme: { sizes } }) =>
      $styling === CAROUSEL_STYLING.pdpThumbnails &&
      `
        button:first-child {
          margin-right: ${sizes.md + 2}px;
        }
    `}
  }

  ${mobile`
    ${({ $styling, theme: { sizes, videoCarousel: carousel } }) => `
      margin: ${
        $styling === CAROUSEL_STYLING.pdpThumbnails
          ? sizes.md
          : carousel.padding / 2
      }px;
    `}
  `}

  ${desktop`
    ${({ $styling, theme: { videoCarousel: carousel } }) =>
      $styling !== CAROUSEL_STYLING.pdpThumbnails &&
      `
        padding: ${carousel.padding - 12}px ${carousel.padding}px 0;
    `};
  `}
`;

const CarouselCell = styled.div`
  width: calc(var(--carousel-video-width));
`;
const CellDescription = styled.div`
  color: ${({ theme }) => theme.colors.shoppableVideoCarouselDescription};
  font-size: var(--carousel-cell-description-font-size);
  line-height: var(--carousel-cell-description-line-height);
  height: var(--carousel-cell-description-height);
  margin-top: var(--carousel-cell-description-margin);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: var(--carousel-cell-description-lines);
  -webkit-box-orient: vertical;
`;

const separatorStyle = css`
  color: ${({ theme: { colors } }) => colors.separator};
  margin: 0 ${({ theme: { sizes } }) => sizes.md}px;
`;

const videoHasDescription = (videosData, isOnPDP, isThumbnailsMode) => {
  const description =
    isOnPDP && isThumbnailsMode ? 'thumbnailDescription' : 'description';
  const videoWithDescription = videosData.find((video) => video[description]);

  return !!videoWithDescription;
};

function VideoCarousel({ videos = [], title }) {
  const theme = useTheme();
  const { isMobile } = useBreakpoint();
  const videosData = useMemo(
    () => videos.map((video) => video?.video?.value?.data).filter(Boolean),
    [videos]
  );

  // This flag is used to determine if the video descriptions are enabled on the PDP. It's only relevant if this component is rendered inside a PDP.
  const pdpVideoDescriptionsEnabled = useHasFeature(
    featureNames.ENABLE_PDP_VIDEO_DESCRIPTIONS
  );
  useVideoRelatedProducts(videosData);

  const [isCarouselLoading, setIsCarouselLoading] = useState(true);
  const {
    openModal,
    videoInModal,
    isOnPDP,
    isThumbnailsMode,
    hasGrayBackground,
    subTitle,
    trackingLabel,
  } = useVideoCarouselContext();

  const { setFlickity } = useCarouselContext();
  const flickityRef = useCallback(
    (flickityNode) => {
      if (flickityNode) {
        setFlickity(flickityNode);
        setIsCarouselLoading(false);
      }
    },
    [setFlickity]
  );
  const hasDescription = useMemo(
    () =>
      pdpVideoDescriptionsEnabled && isOnPDP
        ? videoHasDescription(videosData, isOnPDP, isThumbnailsMode)
        : false,
    [videosData, isOnPDP, isThumbnailsMode, pdpVideoDescriptionsEnabled]
  );
  const carouselConfig = useVideoCarouselConfig(
    videosData.length,
    hasDescription && pdpVideoDescriptionsEnabled
  );
  const cellStyling =
    isOnPDP && isThumbnailsMode ? pdpThumbnailsCellStyle : cellStyle;

  // As required by the design, we should not show the carousel on Desktop devices if there are less than 3 videos.
  // The PdpThumbnails is a special case when the carousel is shown for any number of videos.
  if (
    carouselConfig.hideCarouselWithFewVideos &&
    videosData.length < carouselConfig.oneSlideVideoLimit
  ) {
    return null;
  }

  return (
    <ProductListViewedProvider>
      <FullWidthWrapper
        $styling={carouselConfig.carouselStyling}
        $hasGrayBackground={hasGrayBackground}
        $isOneSlide={carouselConfig.isOneSlide}
        $isMobile={isMobile}
        $hasDescription={carouselConfig.hasDescription}
      >
        <ContainedWrapper>
          {carouselConfig.showTopSectionSeparator && (
            <Separator thickness={1} separatorStyle={separatorStyle} />
          )}
          <HeaderWrapper $styling={carouselConfig.carouselStyling}>
            <SectionTitle
              $isOnPDP={isOnPDP}
              $styling={carouselConfig.carouselStyling}
              $isCentered={carouselConfig.isHeaderCentered}
            >
              {title}
              {!carouselConfig.hideProductNameInTitle && subTitle && (
                <SubTitle>{subTitle}</SubTitle>
              )}
            </SectionTitle>
            {carouselConfig.showCarouselNavigation && (
              <StyledCarouselNavigation
                isAssetCarouselGrid
                iconSize={carouselConfig.carouselNavigationIconSize}
              />
            )}
          </HeaderWrapper>
          <FullWidthCarouselOrOneSlideComponent
            carouselStyling={carouselConfig.carouselStyling}
            isOneSlide={carouselConfig.isOneSlide}
            isCarouselLoading={isCarouselLoading}
            wrapperStyle={{
              maxWidth: theme.breakpoints.laptop.maxWidth,
            }}
            groupCells={carouselConfig.isGroupCells}
            cellAlign="left"
            wrapAround={false}
            freeScroll={false}
            draggable={isMobile}
            cellStyle={cellStyling}
            pageDots={false}
            autoPlay={false}
            prevNextButtons={false}
            contain
            percentPosition={false}
            flickityRef={flickityRef}
            selectedAttraction={isMobile ? 0.025 : 0.01}
            friction={isMobile ? 0.28 : 0.21}
            {...carouselConfig.loadingSkeleton}
          >
            {videosData.map((video, index) => (
              <CarouselCell key={video.id + '-' + index}>
                <VideoWrapper
                  onClick={() => {
                    openModal(video);
                  }}
                >
                  <VideoPreview
                    video={video.video}
                    isPlaying={!videoInModal}
                    wrapperStyle={{
                      width: '100%',
                    }}
                    css={css`
                      & video {
                        object-fit: cover;
                        height: 100% !important;
                        width: 100% !important;
                        ${desktop`
                        max-width: ${({ theme: { videoCarousel: carousel } }) =>
                          carousel.video.desktop.width}px;
                        `}
                        ${mobile`
                        max-width: ${({ theme: { videoCarousel: carousel } }) =>
                          carousel.video.mobile.width}px;
                        `}
                      }
                    `}
                    onEnded={noop}
                    onPlay={noop}
                    onPause={noop}
                    trackingLabel={trackingLabel}
                    onError={(error) =>
                      captureVideoError({
                        error,
                        label: 'Video Carousel',
                        data: { video },
                      })
                    }
                  />
                  {carouselConfig.showVideoAuthor && (
                    <>
                      <ShadowShim
                        top
                        show
                        height={`${carouselConfig.topShadowShimHeight}px`}
                      />
                      <VideoAuthor author={video.author} />
                    </>
                  )}
                  {carouselConfig.showProductInfo && video.products?.[0] && (
                    <VideoCarouselProductInfo
                      videoId={video.video?.id}
                      index={index}
                    />
                  )}
                </VideoWrapper>
                {carouselConfig.hasDescription &&
                  pdpVideoDescriptionsEnabled &&
                  isOnPDP && (
                    <CellDescription>
                      {isOnPDP && isThumbnailsMode
                        ? video.thumbnailDescription
                        : video.description}
                    </CellDescription>
                  )}
              </CarouselCell>
            ))}
          </FullWidthCarouselOrOneSlideComponent>
          {(!isOnPDP || !isThumbnailsMode) && (
            <LegalDisclaimer isOneSlide={carouselConfig.isOneSlide} />
          )}
        </ContainedWrapper>
      </FullWidthWrapper>
      <VideoModal />
    </ProductListViewedProvider>
  );
}

VideoCarousel.propTypes = {
  title: PropTypes.string,
  videos: PropTypes.array.isRequired,
};

export default memo(VideoCarousel);
