import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

export const TextType = {
  HEADER: 'header',
  BODY: 'body',
  LABEL: 'label',
};

const Header = styled.h1`
  ${({ $size }) =>
    getCssFromTypography(typography.header[$size] || typography.header['20'])}
`;

const Body = styled.p`
  ${({ $size }) =>
    getCssFromTypography(typography.body[$size] || typography.body['16'])}
`;

const Label = styled.span`
  ${({ $size }) =>
    getCssFromTypography(typography.label[$size] || typography.label['14'])}
`;

function TypedText({ as, children, className, type, size }) {
  switch (type) {
    case TextType.HEADER:
      return (
        <Header as={as} className={className} $size={size}>
          {children}
        </Header>
      );
    case TextType.BODY:
      return (
        <Body as={as} className={className} $size={size}>
          {children}
        </Body>
      );
    case TextType.LABEL:
      return (
        <Label as={as} className={className} $size={size}>
          {children}
        </Label>
      );
    default:
      return (
        <Body as={as} className={className} $size={size}>
          {children}
        </Body>
      );
  }
}

TypedText.propTypes = {
  as: PropTypes.elementType,
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
};
const objectify = (list) => list.reduce((a, v) => ({ ...a, [v]: v }), {});

const HeaderSize = objectify([
  '12',
  '12med',
  '14',
  '14med',
  '16',
  '18',
  '20',
  '22',
  '40',
  '48',
  '60',
  '100',
]);

const BodySize = objectify(['12', '14', '16', '18', '20', '24']);

const LabelSize = objectify(['12', '14', '16', '18', '24']);

const typography = {
  header: {
    [HeaderSize['100']]: {
      weight: 900,
      size: 100,
      lineHeight: 80,
      letterSpacing: 0,
    },
    [HeaderSize['60']]: {
      weight: 900,
      size: 60,
      lineHeight: 50,
      letterSpacing: 0,
    },
    [HeaderSize['48']]: {
      weight: 700,
      size: 48,
      lineHeight: 36,
      letterSpacing: 0,
    },
    [HeaderSize['40']]: {
      weight: 900,
      size: 40,
      lineHeight: 48,
      letterSpacing: 0,
    },
    [HeaderSize['22']]: {
      weight: 700,
      size: 22,
      lineHeight: 26,
      letterSpacing: 0,
    },
    [HeaderSize['20']]: {
      weight: 700,
      size: 20,
      lineHeight: 24,
      letterSpacing: 0,
    },
    [HeaderSize['18']]: {
      weight: 700,
      size: 18,
      lineHeight: 24,
      letterSpacing: 0,
    },
    [HeaderSize['16']]: {
      weight: 500,
      size: 16,
      lineHeight: 20,
      letterSpacing: 0,
    },
    [HeaderSize['14med']]: {
      weight: 600,
      size: 14,
      lineHeight: 16,
      letterSpacing: 0,
    },
    [HeaderSize['14']]: {
      weight: 400,
      size: 14,
      lineHeight: 16,
      letterSpacing: 0,
    },
    [HeaderSize['12med']]: {
      weight: 600,
      size: 12,
      lineHeight: 16,
      letterSpacing: 0,
    },
    [HeaderSize['12']]: {
      weight: 450,
      size: 12,
      lineHeight: 16,
      letterSpacing: 0,
    },
  },
  body: {
    [BodySize['24']]: {
      weight: 400,
      size: 24,
      lineHeight: 32,
      letterSpacing: 0,
    },
    [BodySize['20']]: {
      weight: 400,
      size: 20,
      lineHeight: 32,
      letterSpacing: 0,
    },
    [BodySize['18']]: {
      weight: 400,
      size: 18,
      lineHeight: 24,
      letterSpacing: 0,
    },
    [BodySize['16']]: {
      weight: 400,
      size: 16,
      lineHeight: 20,
      letterSpacing: 0,
    },
    [BodySize['14']]: {
      weight: 400,
      size: 14,
      lineHeight: 16,
      letterSpacing: 0,
    },
    [BodySize['12']]: {
      weight: 400,
      size: 12,
      lineHeight: 16,
      letterSpacing: 0,
    },
  },
  label: {
    [LabelSize['24']]: {
      weight: 500,
      size: 24,
      lineHeight: 32,
      letterSpacing: 0.2,
    },
    [LabelSize['18']]: {
      weight: 500,
      size: 18,
      lineHeight: 24,
      letterSpacing: 0.2,
    },
    [LabelSize['16']]: {
      weight: 500,
      size: 16,
      lineHeight: 24,
      letterSpacing: 0.2,
    },
    [LabelSize['14']]: {
      weight: 500,
      size: 14,
      lineHeight: 16,
      letterSpacing: 0.2,
    },
    [LabelSize['12']]: {
      weight: 500,
      size: 12,
      lineHeight: 16,
      letterSpacing: 0.2,
    },
  },
};

const getCssFromTypography = ({ weight, size, lineHeight, letterSpacing }) => `
  font-size: ${size}px;
  font-weight: ${weight};
  line-height: ${lineHeight / size};
  letter-spacing: ${letterSpacing / size}em;
`;

export default TypedText;
