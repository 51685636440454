import { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { GET_PRICING_BY_MASTER_PRODUCT_ID } from '~/shoppableVideo/queries';
import { useProductContext } from '~/techstyle-shared/react-components';
import { useLazyQuery } from '~/techstyle-shared/react-graphql';

import { useVideoCarouselContext } from '../VideoCarouselContext';

function processProductData(productData) {
  const { pricing, tags, ...rest } = productData;
  const tagIds = tags?.map((tag) => tag.id);

  return {
    ...pricing,
    promos: pricing?.promo,
    tagIds,
    attributes: [],
    skus: [],
    ...rest,
  };
}

/**
 * Custom hook to fetch and process related product information for a given video.
 *
 * NOTE: This hook contains logic that supports PDPs *AND* non-PDPs. This is important to note
 * because the PDP currently still utilizes the monolith graph, while the non-PDP utilizes the
 * federated graph. This means that the product data is fetched from two different places, and
 * the data returned from each may be slightly different. If you're running into issues with
 * product data not being available, check to see if the PDP or non-PDP version of this hook
 * have parity.
 *
 * Additionally, note that each time you use this hook on non-PDP pages you are requesting over
 * the network and parsing the product data. However, on the PDP you are likely relying on the
 * frontend product context to provide the product data. Be mindful of its use.
 *
 * @param {Object} video - The video object containing product information
 *
 * @returns {Object} - An object containing the product information, loading states, and any errors.
 * @returns {Object} return.product - The processed product information.
 * @returns {boolean} return.isLoadingProduct - Loading state for the product information.
 * @returns {boolean} return.isLoadingProductPricing - Loading state for the product pricing information.
 * @returns {Object} return.error - Any error encountered during the fetching process.
 */
const useVideoRelatedProduct = (video) => {
  const { isOnPDP, getVideoRelatedProduct, isLoadingProducts } =
    useVideoCarouselContext();
  const productFromContext = useProductContext();

  // On a PDP all needed data is available in the product context.
  const [product, setProduct] = useState(productFromContext);
  const [isLoadingProductPricing, setIsLoadingProductPricing] = useState(
    !isOnPDP
  );
  const [fetchProductPricing, { error }] = useLazyQuery(
    GET_PRICING_BY_MASTER_PRODUCT_ID,
    {
      context: { clientName: 'supergraph' },
    }
  );

  const getProductInfo = useCallback(async () => {
    if (video?.products[0]?.masterProductId) {
      const result = await fetchProductPricing({
        variables: {
          productId: video.products[0].masterProductId,
        },
      });

      if (!result.error) {
        const productInfo = processProductData(result.data.productByProductId);

        setProduct((prev) => ({
          ...prev,
          ...productInfo,
        }));
        setIsLoadingProductPricing(false);
      }
    }
  }, [fetchProductPricing, video]);

  useEffect(() => {
    // If this is not a PDP, there is no price information in the product package received with the video.
    // We makes another request to fetch the pricing information for the product.
    if (!isOnPDP) {
      setProduct(getVideoRelatedProduct(video?.video?.id));
      getProductInfo();
    }
  }, [isOnPDP, video, getVideoRelatedProduct, getProductInfo]);

  return {
    product,
    isLoadingProduct: isLoadingProducts,
    isLoadingProductPricing,
    error,
  };
};

useVideoRelatedProduct.propTypes = {
  video: PropTypes.object,
};

export default useVideoRelatedProduct;
