import React, { forwardRef, useRef } from 'react';

import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { isMobile, isSafari } from 'react-device-detect';
import { use100vh } from 'react-div-100vh';
import styled from 'styled-components';

import {
  SkeletonLine as BaseSkeletonLine, // TODO: It will be updated with the new loading animation.
  Video,
} from '~/techstyle-shared/react-components';

const VideoLoadingSkeleton = styled(BaseSkeletonLine).attrs({
  width: '100%',
  height: '100%',
})`
  position: absolute;
`;

/**
 * HLSVideoPlayer is a wrapper around the Video component that automatically determines whether to use HLS or AVC video based on the user's browser (for mobile users).
 *
 * HLS should be used when the user is not on mobile or Safari (which doesn't support AVC).
 * It also allows for a desktop test query param to force HLS on desktop.
 *
 * More HLS info: https://developer.apple.com/streaming/
 *
 * More AVC info: https://en.wikipedia.org/wiki/Advanced_Video_Coding
 */
const HLSVideoPlayer = forwardRef(
  (
    {
      video = {},
      hasControls = false,
      isVisible = true,
      onReady: onReadyFromProps,
      preload = 'auto',
      ...videoProps
    },
    ref
  ) => {
    // The ref is used instead of a state to preclude a jerk of the video at the moment when it is loaded.
    let height = use100vh();
    if (videoProps.height) {
      height = videoProps.height;
    }
    const router = useRouter();
    const isLoaded = useRef(false);
    const onReady = (event) => {
      isLoaded.current = true;
      if (onReadyFromProps && typeof onReadyFromProps === 'function') {
        onReadyFromProps(event);
      }
    };

    if (!video || !height) {
      return null;
    }

    // HLS is only needed when not on mobile or Safari (which doesn't support AVC) or when forced via query param
    const hlsNeeded = !(isMobile || isSafari);

    return (
      <>
        {isVisible && !isLoaded.current && <VideoLoadingSkeleton />}
        <Video
          url={video.avcUrl}
          hasControls={hasControls}
          playsinline
          loop
          ref={ref}
          config={{
            file: {
              forceHLS:
                hlsNeeded ||
                router.query.desktopTest === 'true' ||
                router.query.desktopTest === '1',
              attributes: {
                poster: video.thumbnailUrl,
                preload,
              },
            },
          }}
          aspectRatio={9 / 16}
          onReady={onReady}
          {...videoProps}
        />
      </>
    );
  }
);

HLSVideoPlayer.displayName = 'VideoPlayer';

HLSVideoPlayer.propTypes = {
  isVisible: PropTypes.bool,
  onReady: PropTypes.func,
  preload: PropTypes.string,
  video: PropTypes.shape({
    avcUrl: PropTypes.string,
    thumbnailUrl: PropTypes.string,
  }),
  ...Video.propTypes,
};

export default HLSVideoPlayer;
