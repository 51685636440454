import PropTypes from 'prop-types';
import styled from 'styled-components';

import { fadeInOutStyles } from '~/shoppableVideo/utils/';
import { mobile } from '~/techstyle-shared/react-components';

const ShadowShim = styled.div`
  background: linear-gradient(
    ${(props) => (props.top ? '180deg' : '0deg')},
    ${({ theme }) => theme.colors.shoppableVideoShadowShim70} 0%,
    ${({ theme }) => theme.colors.shoppableVideoShadowShim16} 62%,
    ${({ theme }) => theme.colors.shoppableVideoShadowShim0} 100%
  );
  height: ${(props) => props.height || '100px'};
  position: absolute;
  width: 100%;
  top: ${(props) => (props.top ? 0 : 'auto')};
  bottom: ${(props) => (props.top ? 'auto' : 0)};
  left: 0;
  margin-top: ${(props) => (props.top ? '-1px' : 0)};
  margin-bottom: ${(props) => (props.top ? 0 : '-2px')};
  ${mobile`
    max-width: ${({ theme: { videoCarousel: carousel } }) =>
      carousel.video.mobile.width}px;
  `}
  ${fadeInOutStyles}
`;

export default ShadowShim;

ShadowShim.propTypes = {
  height: PropTypes.string,
  top: PropTypes.bool,
};
