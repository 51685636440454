import { useMemo } from 'react';

import { css } from 'styled-components';

import { useVideoCarouselContext } from '~/shoppableVideo/utils/VideoCarouselContext';
import {
  desktop,
  useBreakpoint,
  useTheme,
} from '~/techstyle-shared/react-components';

export const CAROUSEL_STYLING = {
  pdpThumbnails: 'pdpThumbnails',
};

const HomepageVideoCarouselConfig = {
  default: {
    carouselStyling: 'default',
    hideCarouselWithFewVideos: false,
    showTopSectionSeparator: false,
    isHeaderCentered: false,
    hideProductNameInTitle: true,
    carouselNavigationIconSize: 35,
    showVideoAuthor: true,
    showProductInfo: true,
  },
  desktop: {
    oneSlideVideoLimit: 3,
    showCarouselNavigation: true,
    isGroupCells: true,
  },
  mobile: {
    oneSlideVideoLimit: 1,
    showCarouselNavigation: false,
    isGroupCells: false,
  },
};

const PdpVideoCarouselConfig = {
  default: {
    carouselStyling: 'default',
    showTopSectionSeparator: false,
    hideProductNameInTitle: undefined, // it will be defined in the buildCarouselConfig function.
    carouselNavigationIconSize: 35,
    showVideoAuthor: true,
    showProductInfo: false,
  },
  desktop: {
    hideCarouselWithFewVideos: true,
    oneSlideVideoLimit: 3,
    isHeaderCentered: undefined, // undefined means it will be calculated based on the number of videos
    showCarouselNavigation: true,
    isGroupCells: true,
  },
  mobile: {
    hideCarouselWithFewVideos: false,
    oneSlideVideoLimit: 1,
    isHeaderCentered: true,
    showCarouselNavigation: false,
    isGroupCells: false,
  },
};

const PdpThumbnailsVideoCarouselConfig = {
  default: {
    carouselStyling: CAROUSEL_STYLING.pdpThumbnails,
    oneSlideVideoLimit: 3,
    hideCarouselWithFewVideos: false,
    isHeaderCentered: false,
    hideProductNameInTitle: true,
    showCarouselNavigation: true,
    carouselNavigationIconSize: 30,
    isGroupCells: true,
    showVideoAuthor: false,
    showProductInfo: false,
  },
  desktop: {
    showTopSectionSeparator: false,
  },
  mobile: {
    showTopSectionSeparator: true,
  },
};

const skeletonWrapperStyle = ({ carouselTheme: carousel }) => css`
  height: ${carousel.video.mobile.height}px;

  ${desktop`
    height: ${carousel.video.desktop.height}px;
    margin-bottom: ${carousel.padding}px;
  `}
`;

const buildCarouselConfig = ({
  isOnPDP,
  isThumbnailsMode,
  isMobile,
  hideProductNameInTitle,
  hasDescription,
  numberOfVideos,
  theme,
  carouselTheme,
}) => {
  let carouselConfig = HomepageVideoCarouselConfig;
  if (isOnPDP) {
    carouselConfig = isThumbnailsMode
      ? PdpThumbnailsVideoCarouselConfig
      : PdpVideoCarouselConfig;
  }
  carouselConfig = {
    ...carouselConfig.default,
    ...(isMobile ? carouselConfig.mobile : carouselConfig.desktop),
    hasDescription,
  };

  // Define the height of the curtain that covers a video.
  let topShadowShimHeight = carouselTheme.avatar.desktop + theme.sizes.xxl;
  if (isMobile) {
    topShadowShimHeight -= 8;
  }
  carouselConfig.topShadowShimHeight = topShadowShimHeight;

  // Determine if a ProductName in the Carousel Title should be hidden.
  if (carouselConfig.hideProductNameInTitle === undefined) {
    carouselConfig.hideProductNameInTitle = hideProductNameInTitle;
  }

  // If there are not many videos, we want to show them without the carousel function and with a slightly different design (called "One Slide").
  carouselConfig.isOneSlide =
    numberOfVideos <= carouselConfig.oneSlideVideoLimit;

  // Determine if the Carousel Title should be center-aligned.
  if (carouselConfig.isHeaderCentered === undefined) {
    carouselConfig.isHeaderCentered = carouselConfig.isOneSlide;
  }

  // The blank placeholder is defined for the carousel loader to fix the issue of a big gap while loading the carousel.
  // See the VideoCarouselLoadingSkeleton component which is used a loader for the VideoCarousel.
  carouselConfig.loadingSkeleton = {
    // These params are passed to the Carousel component.
    // See the '~/techstyle-shared/react-components' package for more details.
    showLoadingSkeleton: true,
    loadingSkeletonDesktopCardCount: 0,
    loadingSkeletonMobileCardCount: 0,
    loadingSkeletonWrapperStyle: skeletonWrapperStyle({ carouselTheme }),
  };

  return carouselConfig;
};

/**
 * @description This hook is used to build the configuration object for the VideoCarousel component.
 * @param {number} numberOfVideos - The number of videos in the carousel.
 * @param {boolean} hasDescription - A boolean value indicating if the video has a description.
 * @returns The configuration object for the VideoCarousel component.
 */
const useVideoCarouselConfig = (numberOfVideos, hasDescription) => {
  const theme = useTheme();
  const { isMobile } = useBreakpoint();
  const { isOnPDP, isThumbnailsMode, hideProductNameInTitle, carouselTheme } =
    useVideoCarouselContext();

  const videoCarouselConfig = useMemo(
    () =>
      buildCarouselConfig({
        isOnPDP,
        isThumbnailsMode,
        isMobile,
        hideProductNameInTitle,
        hasDescription,
        numberOfVideos,
        theme,
        carouselTheme,
      }),
    [
      isOnPDP,
      isThumbnailsMode,
      isMobile,
      hideProductNameInTitle,
      hasDescription,
      numberOfVideos,
      theme,
      carouselTheme,
    ]
  );

  return videoCarouselConfig;
};

export default useVideoCarouselConfig;
