import { getIsPromoActive } from './getIsPromoActive';

export const getPromoPricingActiveAsset = ({
  assetContainerOfferLabel,
  product,
  isPrioritizedEnabled = false,
}) => {
  if (assetContainerOfferLabel === undefined) {
    throw new Error('assetContainerOfferLabel is required');
  }

  if (product === undefined) {
    throw new Error('product is required');
  }

  const { data } = assetContainerOfferLabel;

  const assetsArray = [];
  const parentAsset = data?.assets[0];

  if (parentAsset) {
    assetsArray.push(parentAsset);
  }

  const nestedAssets = parentAsset?.container?.data?.assets;

  if (Array.isArray(nestedAssets)) {
    assetsArray.push(...nestedAssets);
  }

  const promoPricingActiveAsset =
    product &&
    isPrioritizedEnabled &&
    assetsArray.filter((asset) => getIsPromoActive(product, asset))[0];

  return promoPricingActiveAsset;
};
