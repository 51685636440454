import React, { useCallback, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledDialog = styled.dialog`
  &::backdrop {
    background-color: rgba(0, 0, 0, 0.25);
  }

  // Use keyframes to animate in and out from opacity:0 scale:0 to opacity:1 scale:1
  @keyframes pop-in {
    from {
      opacity: 0;
      transform: scale(0);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes pop-out {
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      opacity: 0;
      transform: scale(0);
    }
  }

  // Use the keyframes to animate the dialog
  &.animate-in {
    animation: pop-in 0.3s ease-in-out;
    &::backdrop {
      animation: fade-in-backdrop 0.3s ease-in-out;
    }
  }

  &.animate-out {
    animation: pop-out 0.3s ease-in-out;
    &::backdrop {
      animation: fade-out-backdrop 0.3s ease-in-out;
    }
  }

  // Animate the backdrop from rgba(0, 0, 0, 0) to rgba(0, 0, 0, 0.25)

  @keyframes fade-in-backdrop {
    from {
      background-color: rgba(0, 0, 0, 0);
    }
    to {
      background-color: rgba(0, 0, 0, 0.25);
    }
  }

  @keyframes fade-out-backdrop {
    from {
      background-color: rgba(0, 0, 0, 0.25);
    }
    to {
      background-color: rgba(0, 0, 0, 0);
    }
  }
`;

export default function ModalDialog({
  isOpen,
  children,
  onOpen,
  onClose,
  ...rest
}) {
  const dialogRef = useRef();

  const handleClose = useCallback(() => {
    dialogRef.current.classList.remove('animate-in');
    dialogRef.current.classList.add('animate-out');
    dialogRef.current.addEventListener(
      'animationend',
      () => {
        dialogRef.current.close();
        dialogRef.current.classList.remove('animate-out');
        onClose && onClose();
      },
      { once: true }
    );
  }, [onClose]);

  useEffect(() => {
    if (isOpen) {
      dialogRef.current.showModal();
      dialogRef.current.classList.add('animate-in');
      onOpen && onOpen();
    } else if (dialogRef.current.open) {
      handleClose();
    }
  }, [isOpen, onOpen, handleClose]);

  return (
    <StyledDialog aria-modal="true" ref={dialogRef} {...rest}>
      {children}
    </StyledDialog>
  );
}

ModalDialog.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
};
