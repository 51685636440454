import React, { useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { desktop, useBreakpoint } from '~/techstyle-shared/react-components';

const Description = styled.p`
  font-size: ${({
    theme: {
      videoCarousel: { modalDescription },
    },
  }) => modalDescription.font.size};
  line-height:${({
    theme: {
      videoCarousel: { modalDescription },
    },
  }) => modalDescription.font.lineHeight};;
  color: ${({ theme }) => theme.colors.palette.white};
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${({
    $expanded,
    theme: {
      videoCarousel: { modalDescription },
    },
  }) => ($expanded ? 'unset' : modalDescription.lines)}};
  // The box-orient property is deprecated, but widely supported to sustain the -webkit-line-clamp property.
  // The -webkit-line-clamp property is going to be standardized as the line-clamp and is now part of the CSS Overflow Module Level 3 specification.
  -webkit-box-orient: vertical;
`;

const DescriptionContainer = styled.div`
  position: absolute;
  bottom: ${({ theme: { sizes } }) => sizes.xxxl * 3 + (sizes.xxs + 1)}px;
  left: 0;
  padding: 0
    ${({ theme: { videoCarousel: carousel } }) => carousel.padding / 2}px;
  z-index: ${({ theme }) => theme.zIndexes.banner};

  ${desktop`
    // The bottom is calculated based on the height of the video status bar (44px) + paddings.
    bottom: ${({ theme: { sizes } }) => sizes.xxxl - 2}px;
    padding: 0px ${({ theme: { sizes } }) => sizes.md + 6}px;
  `}
`;

// TODO: This component may be adapted to be used in the VideoWithProducts component of the StyleStream feature.
export default function VideoDescription({ description }) {
  const { isMobile } = useBreakpoint();
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);

  const handleDescriptionClick = (e) => {
    e.stopPropagation();
    setIsDescriptionExpanded((prev) => !prev);
  };

  if (!description) {
    return null;
  }

  return (
    <DescriptionContainer
      aria-expanded={isDescriptionExpanded}
      role="button"
      onClick={handleDescriptionClick}
    >
      <Description $expanded={isDescriptionExpanded} $isMobile={isMobile}>
        {description}
      </Description>
    </DescriptionContainer>
  );
}

VideoDescription.propTypes = {
  description: PropTypes.string,
};
