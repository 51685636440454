import React, { memo, useEffect, useMemo, useRef, useState } from 'react';

import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';

import ProductCellPriceDetails from '~/shared/components/ProductCellPriceDetails';
import { useMediaQuery } from '~/shared/utils/useMediaQuery';
import {
  landscapeMobileQuery,
  mobilexsLandscape,
} from '~/shoppableVideo/utils/';
import { useProductTracking } from '~/shoppableVideo/utils/eventTracking';
import { useVideoCarouselContext } from '~/shoppableVideo/utils/VideoCarouselContext';
import { useIsPostReg, useMembership } from '~/techstyle-shared/react-accounts';
import {
  mobile,
  ProductDetail,
  ReviewsDrawerProvider,
  SkeletonLine,
  useBreakpoint,
  useProductImages,
  useTheme,
} from '~/techstyle-shared/react-components';
import { FormattedMessage } from '~/techstyle-shared/react-intl';
import {
  trackProductClicked,
  useProductListViewed,
} from '~/techstyle-shared/react-products';

import ImageSetCollection from './components/ImageSetCollection';

const MobileProductInfoContainerStyle = css`
  align-items: center;
  height: 82px;
  max-width: 100%;
  flex-direction: row;
  gap: ${({ theme: { videoCarousel: carousel } }) => carousel.padding / 2}px;
  position: absolute;
  left: 0;
`;

const ProductInfoContainer = styled.div`
  // Setting z-index to 3 to make sure the product info container is above the video
  z-index: ${({ theme }) => theme.zIndexes.overlay};
  width: 100%;
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  padding: 0;
  padding-top: ${({ theme: { videoCarousel: carousel } }) =>
    carousel.padding / 2}px;
  height: 100%;
  justify-content: center;
  &:focus-visible {
    outline: none;
  }
  ${({ active }) =>
    active &&
    `
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: ${({ theme }) => theme.colors.palette.white};
      }
  `}}

  & picture {
    height: 100%;
    width: auto;
  }

  & img {
    border: 1px solid ${({ theme }) => theme.colors.palette.white};
    object-fit: contain;
    height: 100%;
    width: auto;
  }

  ${mobile`
    ${MobileProductInfoContainerStyle}
    padding: 0 ${({ theme: { videoCarousel: carousel } }) =>
      carousel.padding / 2}px;
    bottom: 57px;
  `}
  ${mobilexsLandscape`
    ${MobileProductInfoContainerStyle}
    padding: 0 ${({ theme: { sizes } }) => sizes.xxl}px;
    bottom: 30px;
    justify-content: start;
  `}
`;

const MobileProductLabelStyle = css`
  font-size: 1.125rem;
  max-width: 240px;
  margin: 0;
  color: ${({ theme: { colors } }) => colors.palette.white};
  flex-grow: 1;
`;

const ProductLabel = styled.div`
  height: auto;
  text-align: left;
  font-size: 1.5rem;
  margin: ${({ theme: { sizes } }) => `${sizes.md + 2}px 0 ${sizes.sm}px 0`};
  white-space: break-spaces;
  font-family: ${({ theme: { fontStacks } }) =>
    fontStacks.standardGtPressuraFamily};
  &,
  & + span > span {
    color: ${({ theme: { colors } }) => colors.textEmphasized};
  }

  ${mobile`${MobileProductLabelStyle}`}
  ${mobilexsLandscape`${MobileProductLabelStyle}`}
`;

const MobileShopNowButtonStyle = css`
  background-color: white;
  color: black;
  height: 2.5rem;
  margin-top: 0;
  padding: revert;
  min-width: 100px;
  width: 100%;
  white-space: nowrap;
  ${mobilexsLandscape`
    margin-left: auto;
  `}
`;

const ShopNowButton = styled.button`
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.palette.black};
  border-radius: ${({ theme: { sizes } }) => sizes.xxxs}px;
  border: none;
  color: ${({ theme }) => theme.colors.palette.white};
  padding: ${({ theme: { sizes } }) => `${sizes.sm}px ${sizes.md}px`};
  width: 100%;
  margin-top: ${({ theme: { sizes } }) => sizes.lg}px;
  font-family: ${({ theme: { fontStacks } }) =>
    fontStacks.standardGtPressuraFamily};
  font-size: 1rem;
  font-style: normal;
  line-height: 1.25;

  ${mobile`
    ${MobileShopNowButtonStyle}
    width: 6.25rem;
  `}
  ${mobilexsLandscape`
    ${MobileShopNowButtonStyle}
    font-weight: bold;
    width: auto;
    flex-grow: 1;
    max-width: 10rem;
  `}
`;

const SkeletonLineWithMarginBottom = styled(SkeletonLine)`
  margin-bottom: ${(props) =>
    props.$marginBottom ? `${props.$marginBottom}px` : 0};
`;

const PriceAndPromosSkeleton = () => (
  <>
    <SkeletonLineWithMarginBottom $marginBottom={8} width={200} height={20} />
    <SkeletonLineWithMarginBottom width={150} height={20} />
  </>
);

/**
 * The ProductCardWithLabel component is used to display the product card with the product label and the product detail.
 * Nota Bene! It is used ONLY in the modal with a full-width video for the VideoCarousel feature.
 */
const ProductCardWithLabel = ({ product, isLoadingProductPricing }) => {
  const theme = useTheme();
  const isLandscapeMobile = useMediaQuery(landscapeMobileQuery(theme));
  const { isMobile } = useBreakpoint();
  const isDesktop = !isMobile && !isLandscapeMobile;
  const imageSets = useProductImages({ product });
  const router = useRouter();
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);
  const containerRef = useRef(null);

  const membership = useMembership();
  const isPostReg = useIsPostReg();
  const currentUserStatus = useMemo(
    () => ({ ...membership, isPostReg }),
    [membership, isPostReg]
  );

  const { observe, unobserve } = useProductListViewed();
  const { isOnPDP, isThumbnailsMode, closeModal, trackingLabel } =
    useVideoCarouselContext();

  const productLabel = product.label?.trim();
  const showProductLabel =
    typeof productLabel === 'string' && productLabel.length !== 0;
  const { pdpUrl: href, productInfo } = useProductTracking({
    product,
    psrc: trackingLabel,
  });

  useEffect(() => {
    const node = containerRef.current;
    if (node) {
      observe(node, productInfo);
      return () => unobserve(node);
    }
  }, [observe, unobserve, productInfo]);

  const redirectToPdp = () => {
    setIsActive(true);
    dispatch(
      trackProductClicked({
        ...productInfo,
        productUrl: href,
      })
    );
    if (isOnPDP) {
      if (!isThumbnailsMode) {
        // We push the scrollIntoView call into the Call Stack to ensure that the carousel closes the modal and moves itself into user's view if needed.
        // This resolves the conflict between our script and the carousel calling ScrollIntoView at the same time when the carousel is not in the user's view.
        setTimeout(() => {
          document.body.scrollIntoView({
            behavior: 'smooth',
          });
        }, 10);
      }
      closeModal();
    } else {
      router.push(href);
    }
  };

  return (
    <ProductInfoContainer ref={containerRef} active={isActive}>
      <ImageSetCollection imageSets={imageSets} productLabel={product?.label} />

      {showProductLabel && <ProductLabel>{productLabel}</ProductLabel>}
      {isDesktop && (
        <ReviewsDrawerProvider>
          {isLoadingProductPricing ? (
            <PriceAndPromosSkeleton />
          ) : (
            <ProductDetail
              currentUserStatus={currentUserStatus}
              product={product}
            >
              <ProductCellPriceDetails product={product} />
            </ProductDetail>
          )}
        </ReviewsDrawerProvider>
      )}
      <ShopNowButton onClick={redirectToPdp}>
        <FormattedMessage id="global_cta.shop_now" defaultMessage="Shop Now">
          {(text) => <span style={{ whiteSpace: 'nowrap' }}>{text}</span>}
        </FormattedMessage>
      </ShopNowButton>
    </ProductInfoContainer>
  );
};

ProductCardWithLabel.propTypes = {
  isLoadingProductPricing: PropTypes.bool.isRequired,
  product: PropTypes.object.isRequired,
};

function isSameProduct(prevProps, nextProps) {
  const prevProduct = prevProps.product;
  const nextProduct = nextProps.product;

  return (
    prevProduct?.masterProductId === nextProduct?.masterProductId &&
    prevProps.isLoadingProductPricing === nextProps.isLoadingProductPricing
  );
}

export default memo(ProductCardWithLabel, isSameProduct);
