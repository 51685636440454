import { useEffect, useCallback } from 'react';

import { GET_SHOPPABLE_VIDEO_DATA_BY_MASTER_PRODUCT_ID } from '~/shoppableVideo/queries';
import { useLazyQuery } from '~/techstyle-shared/react-graphql';

import { useVideoCarouselContext } from '../VideoCarouselContext';

const extractProductIds = (videosData) =>
  videosData
    .filter(Boolean)
    .map((video) => video.products?.[0]?.masterProductId);

const mapVideoIdToProductId = (videosData) =>
  videosData.reduce((acc, video) => {
    if (video.products?.[0]?.masterProductId) {
      acc[video.video.id] = video.products[0].masterProductId;
    }
    return acc;
  }, {});

export function useVideoRelatedProducts(videosData) {
  const { setVideoProducts, setIsLoadingProducts } = useVideoCarouselContext();

  const [fetchProducts, { error, called }] = useLazyQuery(
    GET_SHOPPABLE_VIDEO_DATA_BY_MASTER_PRODUCT_ID,
    {
      context: { clientName: 'supergraph' },
    }
  );

  const getProductsData = useCallback(async () => {
    const productIds = extractProductIds(videosData);
    setIsLoadingProducts(true);
    const productsResponse = await fetchProducts({
      variables: {
        from: 0,
        size: productIds.length,
        productQuery: {
          masterProductId: {
            in: productIds,
          },
        },
      },
      skip: !productIds.length,
    });

    const products = productsResponse?.data?.productListing?.products;
    if (!error && products) {
      const videoIdToProductId = mapVideoIdToProductId(videosData);
      setVideoProducts(products, videoIdToProductId);
      setIsLoadingProducts(false);
    }
  }, [
    videosData,
    setIsLoadingProducts,
    fetchProducts,
    error,
    setVideoProducts,
  ]);

  useEffect(() => {
    // Ensure we only call this once when the data is not yet fetched
    if (!called) {
      getProductsData();
    }
  }, [called, getProductsData]);
}
