import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useVideoCarouselContext } from '~/shoppableVideo/utils/VideoCarouselContext';
import { mobile } from '~/techstyle-shared/react-components';

import ProductCard from '../ProductCard';
import VideoCarouselProductInfoSkeleton from '../VideoCarouselProductInfoSkeleton';

export const ProductInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  bottom: 0;
  left: 0;
  gap: ${({ theme: { sizes } }) => sizes.sm}px;
  align-items: center;
  position: absolute;
  background: ${({ theme }) => theme.colors.shoppableVideoProductInfoOverlay};
  height: 4.375rem;
  padding: 0 ${({ theme: { sizes } }) => sizes.sm + 4}px;
  width: ${({ theme: { videoCarousel: carousel } }) =>
    carousel.video.desktop.width}px;
  ${mobile`
    height: 3.375rem;
    max-width: ${({ theme: { videoCarousel: carousel } }) =>
      carousel.video.mobile.width}px;
  `}
`;

const ProductNameLabel = styled.p`
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.11;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  // The box-orient property has been deprecated.
  // TODO: FLWS-7626 is to fix that.
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  ${mobile`
    font-size: 1rem;
  `}
`;

export default function VideoCarouselProductInfo({ videoId, index }) {
  const { getVideoRelatedProduct, isLoadingProducts, trackingLabel } =
    useVideoCarouselContext();
  const productData = useMemo(
    () => getVideoRelatedProduct(videoId),
    [videoId, getVideoRelatedProduct]
  );

  if (isLoadingProducts) {
    return <VideoCarouselProductInfoSkeleton />;
  }

  if (!productData) {
    return null;
  }

  return (
    <ProductInfoContainer>
      <ProductCard product={productData} index={index} psrc={trackingLabel} />
      <ProductNameLabel>{productData.label}</ProductNameLabel>
    </ProductInfoContainer>
  );
}

VideoCarouselProductInfo.propTypes = {
  index: PropTypes.number.isRequired,
  videoId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};
