import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import PropTypes from 'prop-types';

const Context = createContext({
  flickityPlayer: null,
  setFlickity: () => {},
});

export const useCarouselContext = () => useContext(Context);

export default function CarouselContext({ children }) {
  const [flickityPlayer, setFlickityPlayer] = useState();
  const setFlickity = useCallback(
    (player) => {
      setFlickityPlayer(player);
    },
    [setFlickityPlayer]
  );
  const value = useMemo(
    () => ({
      setFlickity,
      flickityPlayer,
    }),
    [flickityPlayer, setFlickity]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

CarouselContext.propTypes = {
  children: PropTypes.node,
};

CarouselContext.Context = Context;
