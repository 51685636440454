import React, { createContext, useContext } from 'react';

import PropTypes from 'prop-types';

const NavElementContext = createContext();
const { Provider } = NavElementContext;

const useNavElements = () => useContext(NavElementContext);

export default function NavElementsProvider({ children, refs }) {
  return <Provider value={refs}>{children}</Provider>;
}

NavElementsProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  /* references (created with useRef()) to nav elements */
  refs: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.object }),
  ]),
};

export { NavElementContext, useNavElements };
