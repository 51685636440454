import React, { forwardRef } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import PauseIcon from '~/icons/fabletics/PauseIcon';
import PlayIcon from '~/icons/fabletics/PlayIcon';
import VolumeOnIcon from '~/icons/fabletics/VolumeOnIcon';
import VolumeXmarkIcon from '~/icons/fabletics/VolumeXmarkIcon';
import { fadeInOutStyles } from '~/shoppableVideo/utils/';
import { useTheme } from '~/techstyle-shared/react-components';

import ShadowShim from '../ShadowShim';
import VideoProgress from '../VideoProgress';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: transparent;
  scroll-snap-align: start;
  overflow: hidden;
  & iframe {
    width: 100%;
    height: 100%;
  }
`;

// Setting z-index to 2 to make sure the handler is above the video and clicking everywhere on the video will trigger the play/pause action.
const PlayPauseHandler = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: ${({ theme }) => theme.zIndexes.banner};
`;

const PlayPauseButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${fadeInOutStyles}
`;

// Setting z-index to 3 to make sure the sound control is above the video and clicking on it won't trigger the play/pause action.
const SoundControlContainer = styled.div`
  margin-bottom: 8px;
  margin-right: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: ${({ theme }) => theme.zIndexes.overlay};
`;

const StyledVideoProgress = styled(VideoProgress)`
  padding: 18px 15px 18px 28px;
  width: calc(100% - 45px);
`;

const VideoContainer = forwardRef(
  (
    {
      isMuted,
      isPlaying,
      playingInfo,
      posterMode,
      onContainerClick,
      onVolumeControlClick,
      children,
      disabled = false,
      ...rest
    },
    containerRef
  ) => {
    const shouldPlay = isPlaying && !posterMode;
    const theme = useTheme();

    const renderPlayPauseButton = () => {
      if (posterMode) {
        return null;
      }

      if (shouldPlay) {
        return <PauseIcon color="white" />;
      }

      return <PlayIcon />;
    };

    const handleSoundControlClick = (e) => {
      e.stopPropagation();
      onVolumeControlClick && onVolumeControlClick(!isMuted);
    };

    return (
      <Wrapper {...rest} ref={containerRef}>
        {onContainerClick && !disabled && (
          <PlayPauseHandler onClick={onContainerClick} />
        )}
        {children}
        <ShadowShim top show />

        <PlayPauseButtonContainer show={!shouldPlay}>
          {renderPlayPauseButton()}
        </PlayPauseButtonContainer>

        <ShadowShim height={shouldPlay ? '310px' : '620px'} show />

        {!disabled && (
          <StyledVideoProgress
            {...playingInfo}
            remaining={playingInfo.duration * (1 - playingInfo.played)}
            elapsed={playingInfo.duration * playingInfo.played}
            show
          />
        )}

        {!disabled && (
          <SoundControlContainer onClick={handleSoundControlClick}>
            {isMuted ? (
              <VolumeXmarkIcon
                color={theme.colors.shoppableVideoLightGray}
                size="24"
                height="24"
              />
            ) : (
              <VolumeOnIcon
                color={theme.colors.shoppableVideoLightGray}
                width="24"
                height="24"
              />
            )}
          </SoundControlContainer>
        )}
      </Wrapper>
    );
  }
);

VideoContainer.displayName = 'VideoContainer';

VideoContainer.propTypes = {
  children: PropTypes.node.isRequired,
  // Disabling the container, so it won't be clickable (no play/pause). It also hides the progress bar and the sound control.
  disabled: PropTypes.bool,
  isMuted: PropTypes.bool,
  isPlaying: PropTypes.bool,
  onContainerClick: PropTypes.func,
  onVolumeControlClick: PropTypes.func.isRequired,
  playingInfo: PropTypes.shape({
    played: PropTypes.number,
    loaded: PropTypes.number,
    duration: PropTypes.number,
    remaining: PropTypes.number,
  }),
  posterMode: PropTypes.bool,
};

export default VideoContainer;
